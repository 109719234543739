const Copy = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.995 10.6282C3.37608 10.6282 3.25781 9.96636 3.25781 9.34745V5.02489C3.25781 4.31755 3.83122 3.74414 4.53857 3.74414H8.86112C9.48003 3.74414 9.98175 4.24588 9.98175 4.8648"
        stroke="currentColor"
        strokeWidth="1.16735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.48828 7.42528C5.48828 6.71794 6.06169 6.14453 6.76903 6.14453H10.9314C11.6388 6.14453 12.2122 6.71794 12.2122 7.42528V11.5877C12.2122 12.2951 11.6388 12.8685 10.9314 12.8685H6.76903C6.06169 12.8685 5.48828 12.2951 5.48828 11.5877V7.42528Z"
        stroke="currentColor"
        strokeWidth="1.16735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Copy;
