import './index.scss';

import { useEffect, useState } from 'react';

import { SmoothVisibility } from '../smoothVisibility';
import { AnimationTools } from '../../tools/animation';

export interface ILoadingSpinner {
  width?: number;
  height?: number;
  color?: string;
}
export function LoadingSpinner(props: ILoadingSpinner) {
  return process.env.CURRENT_EXTENSION !== 'main' ? (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={props.width ?? 24}
      height={props.height ?? 24}
      viewBox="0 0 50 50"
      className="loading-spinner"
      style={
        {
          enableBackground: 'new 0 0 50 50',
          minWidth: (props.width ?? 24) + 'px',
        } as any
      }
    >
      <path
        fill={props.color}
        d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.6s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  ) : (
    <svg
      stroke={props.color}
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      width={props.width ?? 24}
      height={props.height ?? 24}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="alchemy-animate-spin text-center"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="12" y1="2" x2="12" y2="6"></line>
      <line x1="12" y1="18" x2="12" y2="22"></line>
      <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
      <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
      <line x1="2" y1="12" x2="6" y2="12"></line>
      <line x1="18" y1="12" x2="22" y2="12"></line>
      <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
      <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
    </svg>
  );
}

export interface ILoadingOverlay {
  active: boolean;
  style?: React.CSSProperties;
  isGlobal?: boolean;
  loadingMessage?: string;
}

export function LoadingOverlay(props: ILoadingOverlay) {
  const [isDetached, setIsDetached] = useState<boolean>(!props.active);
  const [isHidden, setIsHidden] = useState<boolean>(!props.active);

  const [lastAnimationTimeout, setLastAnimationTimeout] =
    useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (lastAnimationTimeout) {
      clearTimeout(lastAnimationTimeout);
    }

    const timeout = AnimationTools.autoShowHideTransition(
      props.active,
      setIsDetached,
      setIsHidden
    );

    setLastAnimationTimeout(timeout);
  }, [props.active]);

  return (
    (!isDetached && (
      <div
        className={
          'loading-wrapper' +
          (isHidden ? ' hidden' : '') +
          (props.isGlobal ? ' global' : '')
        }
        style={props.style}
      >
        <div className="loading">
          <LoadingSpinner color="var(--app-accent-dark)" />
          <SmoothVisibility
            visible={!!props.loadingMessage}
            className="loading-message"
          >
            {props.loadingMessage}
          </SmoothVisibility>
        </div>
      </div>
    )) ||
    null
  );
}
