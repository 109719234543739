/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import { v4 } from 'uuid';
import {
  ISSESubscriber,
  IGenerationQueueItem,
  SSEEventType,
  ISSEEvent,
  ISSESubscriberBase,
  IGenerationProps,
  IConversation,
  IGenerationEvents,
  IGenerationSubscriber,
  IMessage,
  IGenerationChunk,
  GenerationEventCallbackWithParams,
  GenerationEventCallback,
} from './interfaces';

export class AIService {
  public static readonly host = 'https://sidebar.alchemy-app.com';

  private activeSSE: EventSource | null = null;
  private activeSSESubscribers: ISSESubscriber[] = [];

  private queue: IGenerationQueueItem[] = [];
  private isProcessing: boolean = false;

  private generationSubscribers: IGenerationSubscriber[] = [];

  private connectionRetryCount = 0;
  private connectionRetryMaxCount = 3;

  private async connectSSE(sessionId: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (!this.activeSSE || this.activeSSE.readyState === EventSource.CLOSED) {
        this.activeSSE = new EventSource(
          `${AIService.host}/generation/${sessionId}/`
        );

        // Handle error events on the EventSource
        this.activeSSE.onerror = async e => {
          console.error('🛑 SSE connection error', e);
          this.connectionRetryCount++;

          if (this.connectionRetryCount >= this.connectionRetryMaxCount) {
            const error = new Error('Failed to connect to EventStream 😥');

            this.disconnectSSE();
            reject(error);
          }
        };

        // Add a listener for the open event to resolve the promise
        this.activeSSE.onopen = () => {
          console.log('🌟 SSE connected');
          resolve(); // Resolve the promise when the connection is open
        };

        // Setup event listeners for other SSE events
        Object.values(SSEEventType).forEach(type => {
          this.activeSSE?.addEventListener(type, (e: MessageEvent<any>) => {
            const parsedData = e.data ? JSON.parse(e.data) : null;
            this.handleSSEEvent({
              type,
              sent_at: parsedData?.send_at ?? null,
              session_id: parsedData?.session_id ?? null,
              data: parsedData?.data ?? null,
            });
          });
        });
      } else {
        this.disconnectSSE();

        await this.connectSSE(sessionId);
        resolve();
      }
    });
  }

  private disconnectSSE() {
    if (
      this.activeSSE?.readyState === EventSource.OPEN ||
      this.activeSSE?.readyState === EventSource.CONNECTING
    ) {
      this.activeSSE.close();
    }

    this.activeSSE = null;

    console.log('💫 SSE disconnected');
  }

  private handleSSEEvent(event: ISSEEvent) {
    this.activeSSESubscribers.forEach(subscriber => {
      if (subscriber.request.session_id === event.session_id) {
        subscriber.onEvent(event, subscriber);
      }
    });
  }

  private subscribeToSSE(props: ISSESubscriberBase): ISSESubscriber {
    const subscriber = {
      id: v4(),
      ...props,
    };
    this.activeSSESubscribers.push(subscriber);

    return subscriber;
  }

  private unsubscribeFromSSE(id: string) {
    this.activeSSESubscribers = this.activeSSESubscribers.filter(
      subscriber => subscriber.id !== id
    );
  }

  private async processQueue() {
    if (this.isProcessing || this.queue.length === 0) {
      if (this.queue.length === 0 && !this.isProcessing) {
        console.log('✅ All queue items have been processed.');
        this.disconnectSSE();
      }
      return;
    }

    const nextItem = this.queue.shift();
    if (nextItem) {
      try {
        await this.connectSSE(nextItem.id);
        this.isProcessing = true;
        this.startGeneration(nextItem);
      } catch (e: any) {
        nextItem.reject(new Error(e.message));
      }
    }
  }

  private enqueue(
    props: IGenerationProps,
    resolve: () => void,
    reject: (reason?: any) => void
  ) {
    const id = v4();

    const queueItem: IGenerationQueueItem = {
      id,
      props,
      resolve,
      reject,
    };

    props.onIdGenerated?.(id);

    console.log('⏩ Added item to queue', queueItem);
    this.queue.push(queueItem);
    this.processQueue();
  }

  public subscribeOnGenerationEvents(
    id: string,
    events: IGenerationEvents
  ): void {
    this.generationSubscribers.push({ id, events });
  }

  private async startGeneration(item: IGenerationQueueItem) {
    let response = '';
    let llmMessage: IMessage | null = null;

    const handleEvent = async (event: ISSEEvent, subscriber: any) => {
      console.log('⚪ Received SSE event', event);

      const generationSubscribersForId = this.generationSubscribers.filter(
        s => s.id === item.id
      );

      const invokeCallbacks = <P>(
        itemCallback: GenerationEventCallbackWithParams<P> | undefined,
        subscriberCallbackName: keyof typeof item.props,
        param: P,
        event: ISSEEvent
      ) => {
        itemCallback?.(param, event);
        generationSubscribersForId.forEach(s =>
          (s.events as any)?.[subscriberCallbackName]?.(param, event)
        );
      };

      const invokeSimpleCallbacks = (
        callbackName: keyof typeof item.props,
        event: ISSEEvent
      ) => {
        (item.props as any)?.[callbackName]?.(event);
        generationSubscribersForId.forEach(s =>
          (s.events as any)?.[callbackName]?.(event)
        );
      };

      const onStreamOpen: GenerationEventCallback = event =>
        invokeSimpleCallbacks('onStreamOpen', event);
      const onCreatedChat: GenerationEventCallbackWithParams<IConversation> = (
        chat,
        event
      ) =>
        invokeCallbacks(item.props.onCreatedChat, 'onCreatedChat', chat, event);
      const onTitleGenerated: GenerationEventCallbackWithParams<string> = (
        title,
        event
      ) =>
        invokeCallbacks(
          item.props.onTitleGenerated,
          'onTitleGenerated',
          title,
          event
        );
      const onCreatedUserMessage: GenerationEventCallbackWithParams<
        IMessage
      > = (message, event) =>
        invokeCallbacks(
          item.props.onCreatedUserMessage,
          'onCreatedUserMessage',
          message,
          event
        );
      const onCreatedAssistantMessage: GenerationEventCallbackWithParams<
        IMessage
      > = (message, event) => {
        llmMessage = message;

        invokeCallbacks(
          item.props.onCreatedAssistantMessage,
          'onCreatedAssistantMessage',
          message,
          event
        );
      };
      const onGenerationStarted: GenerationEventCallback = event =>
        invokeSimpleCallbacks('onGenerationStarted', event);
      const onGenerationChunkReceived: GenerationEventCallbackWithParams<
        IGenerationChunk
      > = (chunk, event) => {
        response += chunk.diff;

        invokeCallbacks(
          item.props.onGenerationChunkReceived,
          'onGenerationChunkReceived',
          chunk,
          event
        );
      };
      const onImageGenerated: GenerationEventCallbackWithParams<string> = (
        url,
        event
      ) =>
        invokeCallbacks(
          item.props.onImageGenerated,
          'onImageGenerated',
          url,
          event
        );
      const onGenerationEnded: GenerationEventCallback = event =>
        invokeSimpleCallbacks('onGenerationEnded', event);
      const onUpdatedAssistantMessage: GenerationEventCallbackWithParams<
        IMessage
      > = (message, event) => {
        llmMessage = message;

        invokeCallbacks(
          item.props.onUpdatedAssistantMessage,
          'onUpdatedAssistantMessage',
          message,
          event
        );
      };
      const onGenerationError: GenerationEventCallbackWithParams<string> = (
        message,
        event
      ) =>
        invokeCallbacks(
          item.props.onGenerationError,
          'onGenerationError',
          message,
          event
        );
      const onCompleted: () => void = () => {
        item.props.onCompleted?.();
        generationSubscribersForId.forEach(s => s.events?.onCompleted?.());
      };

      const { data, type } = event;

      switch (type) {
        case SSEEventType.StreamOpen:
          onStreamOpen?.(event);
          break;
        case SSEEventType.CreatedChat:
          if (data) onCreatedChat?.(data as IConversation, event);
          break;
        case SSEEventType.TitleGenerated:
          if (data?.name) onTitleGenerated?.(data.name, event);
          break;
        case SSEEventType.CreatedUserMessage:
          if (data) onCreatedUserMessage?.(data, event);
          break;
        case SSEEventType.CreatedAssistantMessage:
          if (data) onCreatedAssistantMessage?.(data, event);
          break;
        case SSEEventType.GenerationStarted:
          onGenerationStarted?.(event);
          break;
        case SSEEventType.GenerationChunkReceived:
          if (data?.chunk) onGenerationChunkReceived?.(data.chunk, event);
          break;
        case SSEEventType.ImageGenerated:
          if (data?.image) onImageGenerated?.(data.image.url, event);
          break;
        case SSEEventType.GenerationEnded:
          onGenerationEnded?.(event);
          break;
        case SSEEventType.GenerationError:
          if (data?.error) onGenerationError?.(data.error, event);
          break;
        case SSEEventType.UpdatedAssistantMessage:
          if (data) onUpdatedAssistantMessage?.(data, event);
          break;
        case SSEEventType.Done:
          onCompleted?.();
          item.resolve();

          this.generationSubscribers = this.generationSubscribers.filter(
            s => s.id !== item.id
          );

          cleanup(subscriber.id);
          break;
        default:
          console.warn(`🛑 Unhandled event type: ${type}`);
      }
    };

    const cleanup = (subscriberId: string) => {
      if (subscriberId) this.unsubscribeFromSSE(subscriberId);
      this.isProcessing = false;
      this.processQueue();
    };

    try {
      const request = { ...item.props.request, session_id: item.id };
      const subscriber = this.subscribeToSSE({
        request,
        onEvent: handleEvent,
      });

      if (item.props.signal) {
        item.props.signal.onabort = () => {
          this.unsubscribeFromSSE(subscriber.id);

          this.generationSubscribers
            .filter(s => s.id === item.id)
            .forEach(s => s.events.onAborted?.());

          item.props.onAborted?.();
          item.reject('Aborted');
          this.isProcessing = false;
          this.processQueue();
        };
      }

      console.log('🍀 Generation started:', item);
      await axios.post(
        `${AIService.host}/api/sidebar/generate-tools/`,
        request
      );
    } catch (error) {
      console.error('🛑 Error starting generation:', error);
      item.reject(error);
      this.isProcessing = false;
      this.processQueue();
    }
  }

  async generate(props: IGenerationProps): Promise<void> {
    const modifiedProps: IGenerationProps = {
      ...props,
      request: {
        ...props.request,
      },
    };
    return new Promise((resolve, reject) => {
      this.enqueue(modifiedProps, resolve, reject);
    });
  }
}
