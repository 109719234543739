export function StarsGroup() {
  return (
    <svg
      width="80"
      height="17"
      viewBox="0 0 80 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.20488 1.76836C7.52524 1.10152 8.47476 1.10152 8.79512 1.76836L10.1075 4.50015C10.2361 4.76782 10.4907 4.95282 10.785 4.99241L13.7887 5.39639C14.5219 5.495 14.8153 6.39805 14.2801 6.9088L12.0875 9.00112C11.8727 9.20613 11.7755 9.50548 11.8288 9.79761L12.3727 12.7791C12.5055 13.5069 11.7373 14.065 11.0862 13.7138L8.41874 12.2751C8.15738 12.1342 7.84262 12.1342 7.58126 12.2751L4.91382 13.7138C4.26268 14.065 3.4945 13.5069 3.62728 12.7791L4.17125 9.79761C4.22455 9.50548 4.12728 9.20613 3.91245 9.00112L1.71992 6.9088C1.18471 6.39805 1.47813 5.495 2.21133 5.39639L5.21496 4.99241C5.50927 4.95282 5.76391 4.76782 5.8925 4.50015L7.20488 1.76836Z"
        fill="#FFC500"
      />
      <path
        d="M23.2049 1.76836C23.5252 1.10152 24.4748 1.10152 24.7951 1.76836L26.1075 4.50015C26.2361 4.76782 26.4907 4.95282 26.785 4.99241L29.7887 5.39639C30.5219 5.495 30.8153 6.39805 30.2801 6.9088L28.0875 9.00112C27.8727 9.20613 27.7755 9.50548 27.8288 9.79761L28.3727 12.7791C28.5055 13.5069 27.7373 14.065 27.0862 13.7138L24.4187 12.2751C24.1574 12.1342 23.8426 12.1342 23.5813 12.2751L20.9138 13.7138C20.2627 14.065 19.4945 13.5069 19.6273 12.7791L20.1712 9.79761C20.2245 9.50548 20.1273 9.20613 19.9125 9.00112L17.7199 6.9088C17.1847 6.39805 17.4781 5.495 18.2113 5.39639L21.215 4.99241C21.5093 4.95282 21.7639 4.76782 21.8925 4.50015L23.2049 1.76836Z"
        fill="#FFC500"
      />
      <path
        d="M39.2049 1.76836C39.5252 1.10152 40.4748 1.10152 40.7951 1.76836L42.1075 4.50015C42.2361 4.76782 42.4907 4.95282 42.785 4.99241L45.7887 5.39639C46.5219 5.495 46.8153 6.39805 46.2801 6.9088L44.0875 9.00112C43.8727 9.20613 43.7755 9.50548 43.8288 9.79761L44.3727 12.7791C44.5055 13.5069 43.7373 14.065 43.0862 13.7138L40.4187 12.2751C40.1574 12.1342 39.8426 12.1342 39.5813 12.2751L36.9138 13.7138C36.2627 14.065 35.4945 13.5069 35.6273 12.7791L36.1712 9.79761C36.2245 9.50548 36.1273 9.20613 35.9125 9.00112L33.7199 6.9088C33.1847 6.39805 33.4781 5.495 34.2113 5.39639L37.215 4.99241C37.5093 4.95282 37.7639 4.76782 37.8925 4.50015L39.2049 1.76836Z"
        fill="#FFC500"
      />
      <path
        d="M55.2049 1.76836C55.5252 1.10152 56.4748 1.10152 56.7951 1.76836L58.1075 4.50015C58.2361 4.76782 58.4907 4.95282 58.785 4.99241L61.7887 5.39639C62.5219 5.495 62.8153 6.39805 62.2801 6.9088L60.0875 9.00112C59.8727 9.20613 59.7755 9.50548 59.8288 9.79761L60.3727 12.7791C60.5055 13.5069 59.7373 14.065 59.0862 13.7138L56.4187 12.2751C56.1574 12.1342 55.8426 12.1342 55.5813 12.2751L52.9138 13.7138C52.2627 14.065 51.4945 13.5069 51.6273 12.7791L52.1712 9.79761C52.2245 9.50548 52.1273 9.20613 51.9125 9.00112L49.7199 6.9088C49.1847 6.39805 49.4781 5.495 50.2113 5.39639L53.215 4.99241C53.5093 4.95282 53.7639 4.76782 53.8925 4.50015L55.2049 1.76836Z"
        fill="#FFC500"
      />
      <path
        d="M71.2049 1.76836C71.5252 1.10152 72.4748 1.10152 72.7951 1.76836L74.1075 4.50015C74.2361 4.76782 74.4907 4.95282 74.785 4.99241L77.7887 5.39639C78.5219 5.495 78.8153 6.39805 78.2801 6.9088L76.0875 9.00112C75.8727 9.20613 75.7755 9.50548 75.8288 9.79761L76.3727 12.7791C76.5055 13.5069 75.7373 14.065 75.0862 13.7138L72.4187 12.2751C72.1574 12.1342 71.8426 12.1342 71.5813 12.2751L68.9138 13.7138C68.2627 14.065 67.4945 13.5069 67.6273 12.7791L68.1712 9.79761C68.2245 9.50548 68.1273 9.20613 67.9125 9.00112L65.7199 6.9088C65.1847 6.39805 65.4781 5.495 66.2113 5.39639L69.215 4.99241C69.5093 4.95282 69.7639 4.76782 69.8925 4.50015L71.2049 1.76836Z"
        fill="#FFC500"
      />
    </svg>
  );
}
