import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDarkReasonable as codeTheme } from 'react-syntax-highlighter/dist/cjs/styles/hljs';

import CheckIcon from './icons/CheckIcon';
import CopyIcon from './icons/CopyIcon';

export interface ICodeBlock {
  customTitle?: string;
  customTheme?: {
    [key: string]: React.CSSProperties;
  };
  language: string;
  children: any;
}

const areEqual = (prevProps: ICodeBlock, nextProps: ICodeBlock) => {
  /*
  возвращает true, если nextProps рендерит тот же результат, что и prevProps,
  иначе возвращает false
  */
  return JSON.stringify(prevProps) === JSON.stringify(nextProps);
};

function CodeBlockComponent(props: ICodeBlock) {
  const [copied, setCopied] = useState(false);

  const handleCopy = (content: string) => {
    navigator.clipboard.writeText(content).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  return (
    <div className="code-wrapper">
      <div className="header">
        <p className="language">{props.customTitle ?? props.language}</p>

        {!copied ? (
          <div className="will-copy" onClick={() => handleCopy(props.children)}>
            <button>
              <CopyIcon
                color={
                  copied ? 'var(--app-accent-milk)' : 'var(--app-accent-milk)'
                }
                copied={copied}
              />
            </button>
            Copy code
          </div>
        ) : (
          <div className="copied">
            <button>
              <CheckIcon />
            </button>
            Copied
          </div>
        )}
      </div>
      <SyntaxHighlighter
        style={props.customTheme ?? codeTheme}
        language={props.language}
      >
        {props.children}
      </SyntaxHighlighter>
    </div>
  );
}
export const CodeBlock = React.memo(CodeBlockComponent, areEqual);
