import getVideoId from 'get-video-id';
import { useEffect, useMemo, useState } from 'react';
import { InlineMath } from 'react-katex';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { v4 } from 'uuid';

import { CodeBlock } from '../code';
import { StringTools } from '../../tools/string';

export interface ISmoothText {
  enabled: boolean;
  speed: number;
  text: string;
  id?: string;
  markCitations?: boolean;
  disableMarkdown?: boolean;
  videoURL?: string;
  customTheme?: {
    [key: string]: React.CSSProperties;
  };
}

export const SmoothText = (props: ISmoothText) => {
  const cacheKey = 'alchemyTypingIndex-' + (props.id ?? v4());

  const [currentIndex, setCurrentIndex] = useState(
    ((window as any)?.[cacheKey] as number) ?? 0
  );
  const [onceEnabled] = useState<boolean>(props.enabled);

  useEffect(() => {
    (window as any)[cacheKey] = currentIndex;
  }, [currentIndex]);

  const timestampRegex = /\b(\d{1,2}):(\d{2})(?::(\d{2}))?\b/g;

  // Функция для предварительной обработки текста, заменяя таймштампы на Markdown ссылки
  function preprocessMarkdownText(text: string) {
    return props.videoURL
      ? text.replace(timestampRegex, (match, p1, p2, p3) => {
          const seconds = StringTools.convertTimestampToSeconds(p1, p2, p3);

          return `[${match}](${props.videoURL}&t=${seconds}s)`;
        })
      : text;
  }

  const renderers = useMemo(
    () => ({
      code: (props: any) => {
        const language = props.className?.replace('language-', '') ?? '';

        return (
          <>
            {!language.includes('math') ? (
              <>
                {language ? (
                  <CodeBlock
                    customTheme={props.customTheme}
                    language={language}
                  >
                    {props.children}
                  </CodeBlock>
                ) : (
                  <code>{props.children}</code>
                )}
              </>
            ) : (
              <InlineMath>{props.children?.toString()}</InlineMath>
            )}
          </>
        );
      },
      img: (props: any) => (
        <>
          {props?.href?.includes('sandbox:') ? (
            <img src={props?.href} alt={props?.node?.properties?.alt} />
          ) : null}
        </>
      ),
      a: (p: any) => {
        const isCitation =
          props.markCitations &&
          Array.isArray(p?.children) &&
          (p?.children?.length ?? 0) > 0 &&
          typeof p?.children?.[0] === 'string' &&
          StringTools.isCitation(p?.children);

        return (
          <a
            href={p?.href}
            target="_blank"
            className={isCitation ? 'citation' : ''}
            onClick={e => {
              if (props.videoURL) {
                const currentVideoId = props.videoURL
                  ? (getVideoId(window.location.href)?.id ?? null)
                  : null;
                const linkVideoId = props.videoURL
                  ? (getVideoId(p?.href ?? '')?.id ?? null)
                  : null;

                const linkIsCurrentVideo =
                  props.videoURL && currentVideoId === linkVideoId;

                if (linkIsCurrentVideo) {
                  e.preventDefault();

                  const player = document.querySelector('video');

                  if (player)
                    player.currentTime = StringTools.parseSecondsFromURL(
                      p?.href ?? ''
                    );
                }
              }
            }}
            rel="noopener noreferrer"
          >
            {isCitation
              ? StringTools.getCitationNumber(p?.children?.toString())
              : p?.children}
          </a>
        );
      },
      table: (props: any) => (
        <div className="table-wrapper">
          <table>{props.children}</table>
        </div>
      ),
    }),
    []
  );

  const displayText = useMemo(
    () => (props.enabled ? props.text.slice(0, currentIndex + 5) : props.text),
    [props.text, currentIndex]
  );

  useEffect(() => {
    if (
      (!onceEnabled && !props.disableMarkdown) ||
      currentIndex >= props.text.length
    )
      return;

    let animationFrameId: any;

    const animateText = () => {
      setCurrentIndex(prevIndex => prevIndex + 5 * props.speed);

      // Only continue the animation if we haven't displayed the entire text yet
      if (currentIndex < props.text.length - 1) {
        animationFrameId = requestAnimationFrame(animateText);
      }
    };

    animationFrameId = requestAnimationFrame(animateText);

    return () => cancelAnimationFrame(animationFrameId);
  }, [currentIndex, props.enabled, props.text, onceEnabled]);

  useEffect(() => {
    if (
      (onceEnabled || props.enabled) &&
      (window as any).alchemyWindowInactive
    ) {
      setCurrentIndex(props.text.length);
    }
  }, [props.enabled, props.text]);

  try {
    return (
      <>
        {!props.disableMarkdown ? (
          <ReactMarkdown
            components={renderers}
            remarkPlugins={[gfm, [remarkMath, { singleDollarTextMath: false }]]}
            className="text prose markdown"
          >
            {preprocessMarkdownText(
              displayText
                ? (() => {
                    // Split text into parts inside and outside of backticks
                    const parts = displayText.split(/(`.*?`)/g);

                    for (let i = 0; i < parts.length; i++) {
                      // Check if the part is outside of backticks
                      if (!parts[i].startsWith('`')) {
                        // Perform replacements on this part
                        parts[i] = parts[i]
                          .replace(/\\\[(.*?)\\\]/g, '$$$1$$')
                          .replace(/\\\((.*?)\\\)/g, '$$$1$$');
                      }
                    }

                    // Join the parts back together
                    return parts.join('');
                  })()
                : props.text
            )}
          </ReactMarkdown>
        ) : (
          <p>{displayText ?? props.text}</p>
        )}
      </>
    );
  } catch (e) {
    return <p>{displayText ?? props.text}</p>;
  }
};
