import axios from 'axios';
import { IMainToolsData } from './interfaces';

export class BlobToolsAPI {
  static host = 'https://web.alchemy-app.com/api/';

  static async getMainData(): Promise<IMainToolsData | null> {
    const response = await axios.get(this.host + '/shop/get-tools-prompts/');

    return (response?.data as IMainToolsData) ?? null;
  }
}
