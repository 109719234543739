import './index.scss';
import './prose.scss';

import AlchemyLogo from './assets/Logo';
import { XIcon } from './assets/share/XIcon';
import { LinkedInIcon } from './assets/share/LinkedInIcon';
import { LinkIcon } from './assets/share/LinkIcon';
import { Stars } from './assets/Stars';
import { useAsyncProcessManager } from './tools/async';
import { AsyncProcessManagerContext } from './tools/async/context';
import toast, { Toaster } from 'react-hot-toast';
import { useEffect, useMemo, useState } from 'react';
import { ICategory, IMainToolsData } from './api/interfaces';
import { BlobToolsAPI } from './api';
import { LoadingOverlay } from './components/loading';
import { AIService } from './api/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { SmoothText } from './components/smoothText';
import { StarsGroup } from './assets/StarsGroup';
import { LandingTiles } from './assets/LandingTiles';
import { ProjectorLights } from './assets/ProjectorLights';
import SmoothImage from './components/smoothImage/SmoothImage';
import { Drawing } from './assets/Drawing';
import TextareaAutosize from 'react-textarea-autosize';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const slug = useMemo(() => location.pathname.replaceAll('/', ''), [location]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const aiService = useMemo(() => new AIService(), []);

  const [data, setData] = useState<IMainToolsData | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
    null
  );

  const [textAreaValues, setTextAreaValues] = useState<{
    [key: string]: string;
  }>({});

  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [generatedResponse, setGeneratedResponse] = useState<string | null>(
    null
  );

  const asyncProcessManager = useAsyncProcessManager({
    onFirstActiveStart: () => {
      // setIsLoading(true);
    },
    onAllFinished: () => {
      // setIsLoading(false);
    },
    onError: (e, _, message, process) => {
      if (e.message == 'Network Error') {
        document.dispatchEvent(new CustomEvent('alchemyConnectionLost'));
      } else {
        // toast.error(`🤖 API error '${message}' on ${process.name}`);
        toast.error(`🤖 API error '${message}' on ${process.name}`);
        console.error('🛑 Error detected', e);
      }
    },
  });

  useEffect(() => {
    asyncProcessManager?.doProcess({
      name: 'Get main data',
      onError: () => {
        setIsLoading(false);
        return undefined;
      },
      action: async () => {
        setIsLoading(true);
        const newData = await BlobToolsAPI.getMainData();

        setData(newData);
        setIsLoading(false);
      },
    });
  }, []);

  const promptsInCurrentCategory = useMemo(
    () => (selectedCategory ?? data?.results?.[0])?.prompts ?? [],
    [selectedCategory, data]
  );

  const activePrompt = useMemo(
    () =>
      data?.results
        ?.flatMap(category => category.prompts)
        ?.find(item => item.slug === slug),
    [data, slug]
  );

  const runActivePrompt = () => {
    if (!activePrompt) {
      toast.error('No active prompt');
      return;
    }
    asyncProcessManager.doProcess({
      name: 'Start generation',
      action: async () => {
        setIsGenerating(true);
        setGeneratedResponse('');

        await aiService.generate({
          request: {
            prompt: activePrompt?.id,
            input_fields: textAreaValues,
          },
          onGenerationChunkReceived: chunk => {
            setGeneratedResponse(prev => prev + chunk.diff);
          },
          onGenerationEnded: () => {
            setIsGenerating(false);
          },
        });
      },
    });
  };

  const handleTextAreaChange = (name: string, value: string) => {
    setTextAreaValues(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log(activePrompt);
  }, [activePrompt]);

  useEffect(() => {
    setTextAreaValues({});
  }, [activePrompt]);

  const shareUrl = window.location.href; // Get the current page URL
  const pageTitle = document.title; // Get the current page title (optional)

  const handleShare = (platform: string) => {
    let shareLink = '';

    switch (platform) {
      case 'x':
        shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(pageTitle)}`;
        break;
      case 'linkedin':
        shareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(pageTitle)}`;
        break;
      case 'instagram':
        toast.error(
          'Instagram does not support direct URL sharing. Please share manually.'
        );
        return; // Exit without opening a link
      default:
        return;
    }

    window.open(shareLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <AsyncProcessManagerContext.Provider value={asyncProcessManager}>
      <Toaster
        containerClassName="ktulhu-toaster"
        containerStyle={{
          zIndex: 999999999999999,
          width: '100%',
        }}
        toastOptions={{
          style: {
            border: '1px solid var(--app-light-1a)',
            background: 'var(--app-toaster-background)',
            color: 'var(--app-text)',
            display: 'flex',
          },
        }}
      />
      <div className="container">
        <Stars />

        <header className="header">
          <div className="group">
            <a href="https://alchemy-app.com">
              <AlchemyLogo />
            </a>
            <nav>
              <ul className="nav-links">
                <li>
                  <a href="https://alchemy-app.com/features">Features</a>
                </li>
                <li>
                  <a href="https://alchemy-app.com/pricing">Pricing</a>
                </li>
                <li>
                  <a href="https://alchemy-app.com/help">Help</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="auth-buttons">
            <button
              className="login"
              onClick={() =>
                (window.location.href = 'https://web.alchemy-app.com/sign-in')
              }
            >
              Log In
            </button>
            <button
              className="signup"
              onClick={() =>
                (window.location.href = 'https://web.alchemy-app.com/sign-up')
              }
            >
              Sign Up
            </button>
          </div>
        </header>

        <main className={!activePrompt ? ' landing' : ''}>
          {activePrompt ? (
            <>
              <section className="intro">
                <div className="main-group">
                  <>
                    <div className="prompt-information">
                      <h1>{activePrompt?.name}</h1>
                      <p>{activePrompt?.description}</p>
                    </div>
                    <div className="share-section">
                      <p>Share this prompt:</p>
                      <div className="share-buttons">
                        <button onClick={() => handleShare('x')}>
                          <XIcon />
                        </button>
                        <button onClick={() => handleShare('linkedin')}>
                          <LinkedInIcon />
                        </button>
                        <button
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(shareUrl);
                              toast.success('Link copied to clipboard!');
                            } catch (err) {
                              console.error(err);
                              toast.error('Failed to copy');
                            }
                          }}
                        >
                          <LinkIcon />
                        </button>
                      </div>
                    </div>
                  </>
                </div>

                <div className="prompt-card">
                  <div className="heading">TRY IT HERE</div>
                  <div className="fields">
                    {generatedResponse !== null ? (
                      <div
                        className={
                          'generated-text' + (isGenerating ? ' generating' : '')
                        }
                      >
                        <SmoothText
                          markCitations={true}
                          text={generatedResponse}
                          speed={1}
                          enabled={isGenerating}
                        />
                      </div>
                    ) : (
                      <>
                        {activePrompt?.inputs?.map((input, index) => (
                          <div className="form-group" key={index}>
                            <div className="label">{input.variable_name}</div>
                            <div className="textarea-wrapper">
                              <TextareaAutosize
                                maxRows={7}
                                placeholder={input.placeholder}
                                value={
                                  textAreaValues[input.variable_name] || ''
                                }
                                onChange={e =>
                                  handleTextAreaChange(
                                    input.variable_name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                        <button
                          onClick={() => runActivePrompt()}
                          className="run-prompt"
                        >
                          Run this prompt
                        </button>
                      </>
                    )}
                  </div>
                </div>

                {generatedResponse && (
                  <div className="action-section">
                    <button
                      onClick={() =>
                        (window.location.href =
                          'https://web.alchemy-app.com/sign-up')
                      }
                    >
                      Get more of these prompts
                    </button>
                    <div className="loved-by">
                      <StarsGroup />
                      <p>Loved by over 2000 solopreneur</p>
                    </div>
                  </div>
                )}
              </section>
              <section className="qa">
                <h2>
                  Harness the power of AI with <span>Alchemy</span>
                </h2>

                <div className="qa-group">
                  <h4>What’s Alchemy?</h4>
                  <p>
                    Alchemy is designed to empower solopreneurs and small
                    businesses by providing world-class marketing strategies
                    instantly. With over 100 plug-and-play prompts, it
                    simplifies content creation, research, and marketing
                    execution, helping users enhance their marketing ROI with
                    proven, data-backed tactics.
                  </p>
                </div>

                <div className="qa-group">
                  <h4>Who is it for?</h4>
                  <p>
                    Alchemy is perfect for solopreneurs and small business
                    owners who are overwhelmed by the complexity of marketing
                    tools and strategies. It’s for individuals who want to
                    leverage AI without spending thousands of hours in research
                    or becoming experts themselves. With Alchemy, users can skip
                    the guesswork and instantly access battle-tested marketing
                    strategies that drive real results.
                  </p>
                </div>

                <div className="qa-group">
                  <h4>Why Alchemy?</h4>
                  <p>
                    Most solopreneurs struggle with mediocre marketing results
                    due to generic prompts and overwhelming AI tools. Alchemy
                    simplifies this process by providing expertly curated
                    prompts and marketing tools that eliminate guesswork.
                    Whether you’re struggling with content creation, paid ads,
                    or audience targeting, Alchemy cuts through the noise and
                    delivers high-impact marketing strategies, helping you save
                    time and increase conversion rates effortlessly.
                  </p>
                </div>

                <div className="qa-group">
                  <h4>Are you looking for prompt made by an expert?</h4>
                  <p>
                    Check out our prompt library, or send us an email at{' '}
                    <a href="mailto:hello@alchemy-app.com">
                      hello@alchemy-app.com
                    </a>{' '}
                    with your idea for a prompt, and we’ll designed a prompt
                    with knowledge from industry experts.
                  </p>
                </div>
              </section>
              <section className="prompts-and-categories-wrapper">
                <h2>Check out our other prompts</h2>

                <div className="prompts-and-categories">
                  <div className="categories">
                    {data?.results?.map((category, index) => (
                      <div
                        key={index}
                        onClick={() => setSelectedCategory(category)}
                        className={
                          'category' +
                          (category.id === selectedCategory?.id ||
                          (index === 0 && !selectedCategory)
                            ? ' active'
                            : '')
                        }
                      >
                        <img src={category.white_icon} alt="Category icon" />
                        <span>{category.name}</span>
                      </div>
                    ))}
                  </div>
                  <div className="prompts">
                    {promptsInCurrentCategory?.map(prompt => (
                      <>
                        <div
                          onClick={() => {
                            navigate('/' + prompt.slug);
                            document.body.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            });
                          }}
                          className="prompt"
                        >
                          <p className="title">{prompt.name}</p>
                          <p className="description">{prompt.description}</p>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </section>
              <section className="promo">
                <h2>Time to make the best of AI for marketing</h2>
                <p>You’re in for a surprise.</p>

                <button
                  onClick={() =>
                    (window.location.href =
                      'https://web.alchemy-app.com/sign-up')
                  }
                >
                  Start 7-day free trial
                </button>
              </section>
            </>
          ) : (
            <>
              <section className="intro-landing landing-section">
                <div className="main-info">
                  <h1>
                    Transform your marketing with AI, built for solopreneurs
                  </h1>

                  <div className="advantages">
                    <div className="advantage">
                      Skip thousands of hours in research and strategy
                    </div>
                    <div className="advantage">
                      Apply world class strategies instantly with over 100 plug
                      & play prompts
                    </div>
                    <div className="advantage">
                      Boost your marketing ROI with data-backed, proven tactics
                    </div>
                  </div>

                  <div className="loved-by">
                    <StarsGroup />
                    <p>Loved by over 2000 solopreneur</p>
                  </div>

                  <button
                    onClick={() =>
                      (window.location.href =
                        'https://web.alchemy-app.com/sign-up')
                    }
                  >
                    Get started for free
                  </button>
                </div>

                <LandingTiles />

                <div className="graphics">
                  <ProjectorLights />
                  <SmoothImage alt="Chat screenshot" src="/assets/chat.webp" />
                </div>
              </section>
              <section className="prompts-and-categories-wrapper landing-section">
                <div className="badge">PROMPTS</div>

                <h2>
                  Go from generic to genius with the best prompts on the market
                </h2>

                <p>Plug & play prompts: Your secret weapon</p>

                <div className="prompts-and-categories">
                  <div className="categories">
                    {data?.results?.map((category, index) => (
                      <div
                        key={index}
                        onClick={() => setSelectedCategory(category)}
                        className={
                          'category' +
                          (category.id === selectedCategory?.id ||
                          (index === 0 && !selectedCategory)
                            ? ' active'
                            : '')
                        }
                      >
                        <img src={category.white_icon} alt="Category icon" />
                        <span>{category.name}</span>
                      </div>
                    ))}
                  </div>
                  <div className="prompts">
                    {promptsInCurrentCategory?.map(prompt => (
                      <>
                        <div
                          onClick={() => {
                            navigate('/' + prompt.slug);
                            document.body.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            });
                          }}
                          className="prompt"
                        >
                          <p className="title">{prompt.name}</p>
                          <p className="description">{prompt.description}</p>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </section>
              <section className="problem-solution">
                <div className="problem">
                  <div className="heading">
                    <div className="badge">PROBLEM</div>
                    <h2>
                      Mediocre prompts are killing your marketing potential
                    </h2>
                  </div>
                  <div className="problem-sections">
                    <div className="problem-section">
                      <SmoothImage alt="Emoji" src="/assets/emoji/1.png" />
                      <p>
                        <b>You're buried under a chaos of AI tools, </b>craving
                        simplicity that’s frustratingly out of reach despite
                        promises of easy solutions.
                      </p>
                    </div>

                    <div className="problem-section">
                      <SmoothImage alt="Emoji" src="/assets/emoji/2.png" />
                      <p>
                        <b>You are tired of mediocre prompts </b>and generic ai
                        results, but you understand the need for good marketing.
                      </p>
                    </div>

                    <div className="problem-section">
                      <SmoothImage alt="Emoji" src="/assets/emoji/3.png" />
                      <p>
                        <b>
                          Your stack of marketing tips & tricks is overwhelming{' '}
                        </b>
                        (so you never use them and your business suffers because
                        of it)
                      </p>
                    </div>

                    <div className="problem-section">
                      <SmoothImage alt="Emoji" src="/assets/emoji/4.png" />
                      <p>
                        <b>
                          You feel like your marketing is never persuasive or
                          engaging.{' '}
                        </b>
                        You don’t have time to become an expert and produce
                        marketing that converts.
                      </p>
                    </div>
                  </div>
                </div>

                <Drawing />

                <div className="solution">
                  <div className="heading">
                    <div className="badge">SOLUTION</div>
                    <h2>
                      Achieving top tier results with AI has never been so
                      simple
                    </h2>
                    <p className="heading">
                      Skip months of research, start winning today
                    </p>
                  </div>

                  <div className="articles">
                    <article>
                      <h4>Battle tested strategies</h4>
                      <p>
                        We cut through the noise to deliver proven,
                        battle-tested marketing strategies that drive real
                        results—no trial and error.
                      </p>
                      <div className="image-placeholder"></div>
                    </article>
                    <article>
                      <h4>Curated for you</h4>
                      <p>
                        Our engineers create plug-and-play prompts for every
                        marketing task, from research to viral content—advanced
                        marketing, simplified.
                      </p>
                      <div className="image-placeholder"></div>
                    </article>
                    <article>
                      <h4>Plug & Play Expertise</h4>
                      <p>
                        With one click, our prompts deliver instant expertise,
                        letting you implement advanced strategies with zero
                        setup.
                      </p>
                      <div className="image-placeholder"></div>
                    </article>
                  </div>
                </div>
              </section>
              <section className="comparison">
                <div className="heading">
                  <div className="badge">COMPARISON</div>
                  <h2>Prompts that make your competition sweat</h2>
                  <p>
                    Access unlimited features from top AI models effortlessly.
                  </p>
                </div>
                <div className="articles">
                  <article>
                    <h4>Them</h4>
                    <p>
                      We cut through the noise to deliver proven, battle-tested
                      marketing strategies that drive real results—no trial and
                      error.
                    </p>
                    <div className="image-placeholder"></div>
                  </article>
                  <article>
                    <h4>Alchemy</h4>
                    <p>
                      Our engineers create plug-and-play prompts for every
                      marketing task, from research to viral content—advanced
                      marketing, simplified.
                    </p>
                    <div className="image-placeholder"></div>
                  </article>
                </div>
                <button
                  onClick={() =>
                    (window.location.href =
                      'https://web.alchemy-app.com/sign-up')
                  }
                >
                  Get started for free
                </button>
              </section>
              <section className="solution">
                <div className="heading">
                  <div className="badge">SOLUTION</div>
                  <h2>
                    Achieving top tier results with AI has never been so simple
                  </h2>
                  <p>Skip months of research, start winning today</p>
                </div>
                <div className="articles">
                  <article>
                    <h4>Battle tested strategies</h4>
                    <p>
                      We cut through the noise to deliver proven, battle-tested
                      marketing strategies that drive real results—no trial and
                      error.
                    </p>
                    <div className="image-placeholder"></div>
                  </article>
                  <article>
                    <h4>Curated for you</h4>
                    <p>
                      Our engineers create plug-and-play prompts for every
                      marketing task, from research to viral content—advanced
                      marketing, simplified.
                    </p>
                    <div className="image-placeholder"></div>
                  </article>
                </div>
                <div className="articles">
                  <article>
                    <h4>Battle tested strategies</h4>
                    <p>
                      We cut through the noise to deliver proven, battle-tested
                      marketing strategies that drive real results—no trial and
                      error.
                    </p>
                    <div className="image-placeholder"></div>
                  </article>
                  <article>
                    <h4>Curated for you</h4>
                    <p>
                      Our engineers create plug-and-play prompts for every
                      marketing task, from research to viral content—advanced
                      marketing, simplified.
                    </p>
                    <div className="image-placeholder"></div>
                  </article>
                </div>
                <div className="articles">
                  <article>
                    <h4>Battle tested strategies</h4>
                    <p>
                      We cut through the noise to deliver proven, battle-tested
                      marketing strategies that drive real results—no trial and
                      error.
                    </p>
                    <div className="image-placeholder"></div>
                  </article>
                  <article>
                    <h4>Curated for you</h4>
                    <p>
                      Our engineers create plug-and-play prompts for every
                      marketing task, from research to viral content—advanced
                      marketing, simplified.
                    </p>
                    <div className="image-placeholder"></div>
                  </article>
                </div>
              </section>
            </>
          )}
        </main>

        <footer>
          <a href="https://alchemy-app.com/">
            <AlchemyLogo />
          </a>
          <nav>
            <ul className="nav-links">
              <li>
                <a href="https://alchemy-app.com/privacy">Privacy</a>
              </li>
              <li>
                <a href="https://alchemy-app.com/terms">Terms</a>
              </li>
              <li>
                <a href="https://alchemy-app.com/support">Support</a>
              </li>
              <li>
                <a href="https://alchemy-app.com/">
                  © {new Date().getFullYear()} Alchemy
                </a>
              </li>
            </ul>
          </nav>
        </footer>
      </div>
      <LoadingOverlay isGlobal={true} active={isLoading} />
    </AsyncProcessManagerContext.Provider>
  );
}

export default App;
