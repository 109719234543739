export const Checkmark = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8502_31806)">
        <path
          d="M8 0.800781C3.6 0.800781 0 4.40078 0 8.80078C0 13.2008 3.6 16.8008 8 16.8008C12.4 16.8008 16 13.2008 16 8.80078C16 4.40078 12.4 0.800781 8 0.800781ZM11.707 7.50778L7.707 11.5078C7.512 11.7028 7.256 11.8008 7 11.8008C6.744 11.8008 6.488 11.7028 6.293 11.5078L4.293 9.50778C3.902 9.11678 3.902 8.48478 4.293 8.09378C4.684 7.70278 5.316 7.70278 5.707 8.09378L7 9.38678L10.293 6.09378C10.684 5.70278 11.316 5.70278 11.707 6.09378C12.098 6.48478 12.098 7.11678 11.707 7.50778Z"
          fill="#777777"
        />
      </g>
      <defs>
        <clipPath id="clip0_8502_31806">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.800781)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
