export default function AlchemyLogo(props: { dark?: boolean }) {
  if (props.dark)
    return (
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="alchemy-logo"
      >
        <path
          d="M6.80983 1.22266C4.86998 1.22266 2.85497 2.73509 2.31525 4.59621L0.192811 11.915C-0.212383 13.3122 0.63765 14.4421 2.09397 14.4421H4.94601L7.97545 10.1038L6.22232 5.06614L10.6029 8.10945L15.6537 6.37823L16.4253 3.76165C16.8387 2.35979 15.9888 1.22266 14.5276 1.22266H6.80983Z"
          fill="#222222"
        />
        <path
          d="M7.15869 7.75432C6.86731 8.1047 6.64465 8.50256 6.52239 8.92414L4.15951 17.072C3.75445 18.4688 4.60498 19.5983 6.06179 19.5983H13.8567C15.771 19.5983 17.7639 18.1255 18.3328 16.2903L20.6071 8.95385C21.0459 7.53823 20.196 6.37891 18.7194 6.37891L15.6548 6.37891L12.5984 10.7376L14.3515 15.7753L9.97092 12.732L4.94712 14.4428L7.97656 10.1045L7.15869 7.75432Z"
          fill="#222222"
          fill-opacity="0.6"
        />
        <path
          d="M15.6548 6.37891L9.91516 6.37891C9.45609 6.37891 8.99147 6.49103 8.55734 6.68822L10.6041 8.11012L15.6548 6.37891Z"
          fill="#222222"
          fill-opacity="0.6"
        />
      </svg>
    );

  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="alchemy-logo"
    >
      <path
        d="M6.51293 1.87598C4.6323 1.87598 2.67881 3.34223 2.15557 5.14653L0.0979305 12.2418C-0.294893 13.5964 0.529188 14.6918 1.94105 14.6918H4.70601L7.64296 10.486L5.94336 5.60212L10.1902 8.55251L15.0868 6.87415L15.8348 4.33745C16.2356 2.97839 15.4116 1.87598 13.9951 1.87598H6.51293Z"
        fill="white"
      />
      <path
        d="M6.8492 8.20744C6.56671 8.54713 6.35085 8.93284 6.23233 9.34155L3.94159 17.2407C3.54889 18.5948 4.37346 19.6899 5.78579 19.6899H13.3427C15.1985 19.6899 17.1306 18.262 17.6821 16.4828L19.887 9.37035C20.3124 7.99795 19.4885 6.87403 18.0569 6.87403L15.086 6.87403L12.1228 11.0997L13.8224 15.9835L9.57557 13.0331L4.70515 14.6917L7.6421 10.4859L6.8492 8.20744Z"
        fill="white"
        fill-opacity="0.6"
      />
      <path
        d="M15.086 6.87403L9.52151 6.87402C9.07646 6.87402 8.62602 6.98272 8.20514 7.1739L10.1894 8.55238L15.086 6.87403Z"
        fill="white"
        fill-opacity="0.6"
      />
    </svg>
  );
}
