export function XIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1708 2.61719H17.9275L11.905 9.50052L18.99 18.8672H13.4417L9.09666 13.1864L4.125 18.8672H1.36666L7.80833 11.5047L1.0125 2.61719H6.7L10.6275 7.80969L15.1692 2.61719H15.1708ZM14.2033 17.2172H15.7308L5.87083 4.18052H4.23166L14.2033 17.2172Z"
        fill="white"
      />
    </svg>
  );
}
