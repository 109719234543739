export const Document = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8502_31737)">
        <path
          d="M4.11719 3.92831C4.11719 3.35737 4.58003 2.89453 5.15097 2.89453H10.2676C10.5418 2.89453 10.8047 3.00345 10.9986 3.19732L14.3402 6.53891C14.534 6.73278 14.643 6.99572 14.643 7.2699V15.018C14.643 15.5889 14.1801 16.0517 13.6092 16.0517H5.15097C4.58003 16.0517 4.11719 15.5889 4.11719 15.018V3.92831Z"
          fill="#DCDCDC"
        />
        <path
          d="M4.19472 3.92831C4.19472 3.40019 4.62285 2.97206 5.15097 2.97206H10.2676C10.5212 2.97206 10.7644 3.07281 10.9438 3.25214L14.2853 6.59373C14.4647 6.77306 14.5654 7.01629 14.5654 7.2699V15.018C14.5654 15.5461 14.1373 15.9742 13.6092 15.9742H5.15097C4.62285 15.9742 4.19472 15.5461 4.19472 15.018V3.92831Z"
          stroke="white"
          strokeOpacity="0.17"
          strokeWidth="0.155067"
        />
        <g clipPath="url(#clip1_8502_31737)">
          <g filter="url(#filter0_dii_8502_31737)">
            <path
              d="M10.4439 9.27522C10.7249 8.9942 11.1805 8.9942 11.4615 9.27522C11.7426 9.55623 11.7426 10.0119 11.4615 10.2929L10.4287 11.3257C10.2524 11.502 10.0134 11.601 9.76415 11.601H9.32373C9.21992 11.601 9.13577 11.5168 9.13577 11.413V10.9726C9.13577 10.7234 9.23478 10.4843 9.41103 10.3081L10.4439 9.27522Z"
              fill="black"
              fillOpacity="0.12"
              shapeRendering="crispEdges"
            />
            <path
              d="M10.4439 9.27522C10.7249 8.9942 11.1805 8.9942 11.4615 9.27522C11.7426 9.55623 11.7426 10.0119 11.4615 10.2929L10.4287 11.3257C10.2524 11.502 10.0134 11.601 9.76415 11.601H9.32373C9.21992 11.601 9.13577 11.5168 9.13577 11.413V10.9726C9.13577 10.7234 9.23478 10.4843 9.41103 10.3081L10.4439 9.27522Z"
              fill="url(#paint0_linear_8502_31737)"
              fillOpacity="0.24"
              shapeRendering="crispEdges"
            />
            <path
              d="M7.82005 11.2251C7.43942 11.2251 7.13086 11.5336 7.13086 11.9143C7.13086 12.2949 7.43942 12.6034 7.82005 12.6034H10.4515C10.6245 12.6034 10.7648 12.7437 10.7648 12.9167C10.7648 13.0897 10.6245 13.23 10.4515 13.23H9.32373C9.21992 13.23 9.13577 13.3141 9.13577 13.4179C9.13577 13.5217 9.21992 13.6059 9.32373 13.6059H10.4515C10.8321 13.6059 11.1407 13.2973 11.1407 12.9167C11.1407 12.5361 10.8321 12.2275 10.4515 12.2275H7.82005C7.64703 12.2275 7.50678 12.0873 7.50678 11.9143C7.50678 11.7412 7.64703 11.601 7.82005 11.601H8.44658C8.55039 11.601 8.63454 11.5168 8.63454 11.413C8.63454 11.3092 8.55039 11.2251 8.44658 11.2251H7.82005Z"
              fill="black"
              fillOpacity="0.12"
              shapeRendering="crispEdges"
            />
            <path
              d="M7.82005 11.2251C7.43942 11.2251 7.13086 11.5336 7.13086 11.9143C7.13086 12.2949 7.43942 12.6034 7.82005 12.6034H10.4515C10.6245 12.6034 10.7648 12.7437 10.7648 12.9167C10.7648 13.0897 10.6245 13.23 10.4515 13.23H9.32373C9.21992 13.23 9.13577 13.3141 9.13577 13.4179C9.13577 13.5217 9.21992 13.6059 9.32373 13.6059H10.4515C10.8321 13.6059 11.1407 13.2973 11.1407 12.9167C11.1407 12.5361 10.8321 12.2275 10.4515 12.2275H7.82005C7.64703 12.2275 7.50678 12.0873 7.50678 11.9143C7.50678 11.7412 7.64703 11.601 7.82005 11.601H8.44658C8.55039 11.601 8.63454 11.5168 8.63454 11.413C8.63454 11.3092 8.55039 11.2251 8.44658 11.2251H7.82005Z"
              fill="url(#paint1_linear_8502_31737)"
              fillOpacity="0.24"
              shapeRendering="crispEdges"
            />
          </g>
        </g>
        <path
          d="M10.6816 3.13086L14.4746 6.8416H12.7492C11.6073 6.8416 10.6816 5.91593 10.6816 4.77404V3.13086Z"
          fill="#CDCDCD"
        />
      </g>
      <defs>
        <filter
          id="filter0_dii_8502_31737"
          x="6.98318"
          y="8.99061"
          width="4.83638"
          height="4.83638"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.0738415" />
          <feGaussianBlur stdDeviation="0.0738415" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8502_31737"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8502_31737"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.221525" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.64 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_8502_31737"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-0.0295366" />
          <feGaussianBlur stdDeviation="0.110762" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.84 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_innerShadow_8502_31737"
            result="effect3_innerShadow_8502_31737"
          />
        </filter>
        <linearGradient
          id="paint0_linear_8502_31737"
          x1="9.42113"
          y1="13.6059"
          x2="9.42113"
          y2="9.03158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8502_31737"
          x1="9.42113"
          y1="13.6059"
          x2="9.42113"
          y2="9.03158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_8502_31737">
          <rect
            width="10.5258"
            height="13.1572"
            fill="white"
            transform="translate(4.12109 2.89453)"
          />
        </clipPath>
        <clipPath id="clip1_8502_31737">
          <rect
            width="6.01473"
            height="6.01473"
            fill="white"
            transform="translate(6.38672 8.3457)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
