import { ReactNode, useState } from 'react';
import './index.scss';

export const Accordeon = (props: {
  title?: string | ReactNode;
  children?: ReactNode;
  initState?: boolean;
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(props.initState ?? false);

  return (
    <div
      className="accordeon-wrapper"
      onClick={() => setIsOpened(prev => !prev)}
    >
      <div className="accordeon-title">{props.title}</div>
      <div className={`accordeon-body ${isOpened ? 'opened' : ''}`}>
        {props.children}
      </div>
    </div>
  );
};
