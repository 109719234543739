export const Hashtag = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
    >
      <path
        d="M9.6549 4.3418L7.52148 16.7156"
        stroke="currentColor"
        strokeWidth="1.53606"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.774 4.3418L12.6406 16.7156"
        stroke="currentColor"
        strokeWidth="1.53606"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.333 7.75586H5.8125"
        stroke="currentColor"
        strokeWidth="1.53606"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4814 13.3027H4.96094"
        stroke="currentColor"
        strokeWidth="1.53606"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
