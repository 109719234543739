export function LandingTiles() {
  return (
    <svg
      width="324"
      height="54"
      viewBox="0 0 324 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="tiles"
    >
      <g filter="url(#filter0_d_7163_6455)">
        <rect
          x="4.44446"
          width="46.2222"
          height="46.2222"
          rx="10.6667"
          fill="url(#paint0_linear_7163_6455)"
          shape-rendering="crispEdges"
        />
        <rect
          x="4.8889"
          y="0.444444"
          width="45.3333"
          height="45.3333"
          rx="10.2222"
          stroke="#2D2B32"
          stroke-width="0.888889"
          shape-rendering="crispEdges"
        />
        <path
          d="M28.4358 32.5555C33.2253 32.5555 38.3569 30.1607 38.3569 24.0028C38.3569 17.8449 33.2253 15.4502 28.4358 15.4502C23.6463 15.4502 18.5148 17.8449 18.5148 24.0028C18.5148 25.412 18.7835 26.6242 19.2554 27.6556C19.5495 28.2986 19.7145 29.0076 19.5842 29.7024L19.2254 31.616C19.0481 32.5619 19.8767 33.3906 20.8226 33.2133L25.2117 32.3903C25.5567 32.3256 25.9104 32.3312 26.257 32.3862C26.9785 32.5006 27.7111 32.5555 28.4358 32.5555Z"
          stroke="#F30404"
          stroke-width="2.40615"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.0148 24.0035C25.0148 24.3814 24.7084 24.6878 24.3306 24.6878C23.9527 24.6878 23.6464 24.3814 23.6464 24.0035C23.6464 23.6257 23.9527 23.3193 24.3306 23.3193C24.7084 23.3193 25.0148 23.6257 25.0148 24.0035Z"
          stroke="#F30404"
          stroke-width="1.6041"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.12 24.0035C29.12 24.3814 28.8136 24.6878 28.4358 24.6878C28.058 24.6878 27.7516 24.3814 27.7516 24.0035C27.7516 23.6257 28.058 23.3193 28.4358 23.3193C28.8136 23.3193 29.12 23.6257 29.12 24.0035Z"
          stroke="#F30404"
          stroke-width="1.6041"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.2254 24.0035C33.2254 24.3814 32.919 24.6878 32.5411 24.6878C32.1633 24.6878 31.8569 24.3814 31.8569 24.0035C31.8569 23.6257 32.1633 23.3193 32.5411 23.3193C32.919 23.3193 33.2254 23.6257 33.2254 24.0035Z"
          stroke="#F30404"
          stroke-width="1.6041"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g filter="url(#filter1_d_7163_6455)">
        <rect
          x="71.6666"
          width="46.2222"
          height="46.2222"
          rx="10.6667"
          fill="url(#paint1_linear_7163_6455)"
          shape-rendering="crispEdges"
        />
        <rect
          x="72.1111"
          y="0.444444"
          width="45.3333"
          height="45.3333"
          rx="10.2222"
          stroke="#2D2B32"
          stroke-width="0.888889"
          shape-rendering="crispEdges"
        />
        <rect
          x="83.5817"
          y="15.8623"
          width="22.4016"
          height="14.4951"
          rx="2.63548"
          stroke="#9DF57F"
          stroke-width="2.37193"
        />
        <path
          d="M88.6112 25.2881H100.942"
          stroke="#9DF57F"
          stroke-width="2.37193"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M88.6112 20.9365L94.541 20.9365"
          stroke="#9DF57F"
          stroke-width="2.37193"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g filter="url(#filter2_d_7163_6455)">
        <rect
          x="138.889"
          width="46.2222"
          height="46.2222"
          rx="10.6667"
          fill="url(#paint2_linear_7163_6455)"
          shape-rendering="crispEdges"
        />
        <rect
          x="139.333"
          y="0.444444"
          width="45.3333"
          height="45.3333"
          rx="10.2222"
          stroke="#2D2B32"
          stroke-width="0.888889"
          shape-rendering="crispEdges"
        />
        <path
          d="M171.556 14.8724C171.556 14.1447 170.966 13.5547 170.239 13.5547H164.638C163.183 13.5547 162.003 14.7346 162.003 16.1902V32.6619L163.094 31.5703C164.083 30.5817 165.424 30.0264 166.822 30.0264H170.239C170.966 30.0264 171.556 29.4365 171.556 28.7087V14.8724Z"
          stroke="#9EA5FF"
          stroke-width="2.37193"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M152.448 14.8724C152.448 14.1447 153.038 13.5547 153.766 13.5547H159.366C160.822 13.5547 162.002 14.7346 162.002 16.1902V32.6619L160.91 31.5703C159.921 30.5817 158.581 30.0264 157.183 30.0264H153.766C153.038 30.0264 152.448 29.4365 152.448 28.7087V14.8724Z"
          stroke="#9EA5FF"
          stroke-width="2.37193"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g filter="url(#filter3_d_7163_6455)">
        <rect
          x="206.111"
          width="46.2222"
          height="46.2222"
          rx="10.6667"
          fill="url(#paint3_linear_7163_6455)"
          shape-rendering="crispEdges"
        />
        <rect
          x="206.556"
          y="0.444444"
          width="45.3333"
          height="45.3333"
          rx="10.2222"
          stroke="#2D2B32"
          stroke-width="0.888889"
          shape-rendering="crispEdges"
        />
        <path
          d="M223.61 32.6619H234.811C236.267 32.6619 237.447 31.482 237.447 30.0264V19.1551L231.846 13.5547H223.61C222.155 13.5547 220.975 14.7346 220.975 16.1902V30.0264C220.975 31.482 222.155 32.6619 223.61 32.6619Z"
          stroke="#E7FC8A"
          stroke-width="2.37193"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M237.122 19.4871H231.522V13.8867"
          stroke="#E7FC8A"
          stroke-width="2.37193"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M226.248 27.3906H232.178"
          stroke="#E7FC8A"
          stroke-width="2.37193"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M226.248 23.4375H232.178"
          stroke="#E7FC8A"
          stroke-width="2.37193"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <g filter="url(#filter4_d_7163_6455)">
        <rect
          x="273.333"
          width="46.2222"
          height="46.2222"
          rx="10.6667"
          fill="url(#paint4_linear_7163_6455)"
          shape-rendering="crispEdges"
        />
        <rect
          x="273.778"
          y="0.444444"
          width="45.3333"
          height="45.3333"
          rx="10.2222"
          stroke="#2D2B32"
          stroke-width="0.888889"
          shape-rendering="crispEdges"
        />
        <path
          d="M292.488 16.1953H290.84C289.385 16.1953 288.205 17.3753 288.205 18.8308V30.0316C288.205 31.4871 289.385 32.667 290.84 32.667H302.041C303.497 32.667 304.677 31.4871 304.677 30.0316V18.8308C304.677 17.3753 303.497 16.1953 302.041 16.1953H300.394"
          stroke="#FF78BC"
          stroke-width="1.97661"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M299.075 18.1668H293.804C293.076 18.1668 292.486 17.5768 292.486 16.849V14.8724C292.486 14.1447 293.076 13.5547 293.804 13.5547H299.075C299.803 13.5547 300.393 14.1447 300.393 14.8724V16.849C300.393 17.5768 299.803 18.1668 299.075 18.1668Z"
          stroke="#FF78BC"
          stroke-width="1.97661"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M293.471 23.4375H299.4"
          stroke="#FF78BC"
          stroke-width="1.97661"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M293.471 27.3906H299.4"
          stroke="#FF78BC"
          stroke-width="1.97661"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_7163_6455"
          x="0.888902"
          y="0"
          width="53.3333"
          height="53.3338"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.55556" />
          <feGaussianBlur stdDeviation="1.77778" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7163_6455"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7163_6455"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_7163_6455"
          x="68.1111"
          y="0"
          width="53.3333"
          height="53.3338"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.55556" />
          <feGaussianBlur stdDeviation="1.77778" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7163_6455"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7163_6455"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_7163_6455"
          x="135.333"
          y="0"
          width="53.3333"
          height="53.3338"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.55556" />
          <feGaussianBlur stdDeviation="1.77778" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7163_6455"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7163_6455"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_7163_6455"
          x="202.556"
          y="0"
          width="53.3333"
          height="53.3338"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.55556" />
          <feGaussianBlur stdDeviation="1.77778" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7163_6455"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7163_6455"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_7163_6455"
          x="269.778"
          y="0"
          width="53.3333"
          height="53.3338"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.55556" />
          <feGaussianBlur stdDeviation="1.77778" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7163_6455"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7163_6455"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7163_6455"
          x1="27.5556"
          y1="0"
          x2="27.5556"
          y2="46.2222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#272440" />
          <stop offset="1" stop-color="#191B31" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7163_6455"
          x1="94.7777"
          y1="0"
          x2="94.7777"
          y2="46.2222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#272440" />
          <stop offset="1" stop-color="#191B31" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7163_6455"
          x1="162"
          y1="0"
          x2="162"
          y2="46.2222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#272440" />
          <stop offset="1" stop-color="#191B31" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7163_6455"
          x1="229.222"
          y1="0"
          x2="229.222"
          y2="46.2222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#272440" />
          <stop offset="1" stop-color="#191B31" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7163_6455"
          x1="296.444"
          y1="0"
          x2="296.444"
          y2="46.2222"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#272440" />
          <stop offset="1" stop-color="#191B31" />
        </linearGradient>
      </defs>
    </svg>
  );
}
