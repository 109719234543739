export function ProjectorLights() {
  return (
    <svg
      className="projector-lights"
      width="1032"
      height="991"
      viewBox="0 0 1032 991"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2" filter="url(#filter0_f_7163_6226)">
        <ellipse
          cx="59.5009"
          cy="416"
          rx="25.5"
          ry="540.938"
          fill="url(#paint0_linear_7163_6226)"
        />
      </g>
      <g opacity="0.2" filter="url(#filter1_f_7163_6226)">
        <ellipse
          cx="142.501"
          cy="416"
          rx="25.5"
          ry="540.938"
          fill="url(#paint1_linear_7163_6226)"
        />
      </g>
      <g opacity="0.2" filter="url(#filter2_f_7163_6226)">
        <ellipse
          cx="225.501"
          cy="416"
          rx="25.5"
          ry="540.938"
          fill="url(#paint2_linear_7163_6226)"
        />
      </g>
      <g opacity="0.2" filter="url(#filter3_f_7163_6226)">
        <ellipse
          cx="308.501"
          cy="416"
          rx="25.5"
          ry="540.938"
          fill="url(#paint3_linear_7163_6226)"
        />
      </g>
      <g opacity="0.2" filter="url(#filter4_f_7163_6226)">
        <ellipse
          cx="391.501"
          cy="416"
          rx="25.5"
          ry="540.938"
          fill="url(#paint4_linear_7163_6226)"
        />
      </g>
      <g opacity="0.2" filter="url(#filter5_f_7163_6226)">
        <ellipse
          cx="474.501"
          cy="416"
          rx="25.5"
          ry="540.938"
          fill="url(#paint5_linear_7163_6226)"
        />
      </g>
      <g opacity="0.2" filter="url(#filter6_f_7163_6226)">
        <ellipse
          cx="557.501"
          cy="416"
          rx="25.5"
          ry="540.938"
          fill="url(#paint6_linear_7163_6226)"
        />
      </g>
      <g opacity="0.2" filter="url(#filter7_f_7163_6226)">
        <ellipse
          cx="640.501"
          cy="416"
          rx="25.5"
          ry="540.938"
          fill="url(#paint7_linear_7163_6226)"
        />
      </g>
      <g opacity="0.2" filter="url(#filter8_f_7163_6226)">
        <ellipse
          cx="723.501"
          cy="416"
          rx="25.5"
          ry="540.938"
          fill="url(#paint8_linear_7163_6226)"
        />
      </g>
      <g opacity="0.2" filter="url(#filter9_f_7163_6226)">
        <ellipse
          cx="806.501"
          cy="416"
          rx="25.5"
          ry="540.938"
          fill="url(#paint9_linear_7163_6226)"
        />
      </g>
      <g opacity="0.2" filter="url(#filter10_f_7163_6226)">
        <ellipse
          cx="889.501"
          cy="416"
          rx="25.5"
          ry="540.938"
          fill="url(#paint10_linear_7163_6226)"
        />
      </g>
      <g opacity="0.2" filter="url(#filter11_f_7163_6226)">
        <ellipse
          cx="972.501"
          cy="416"
          rx="25.5"
          ry="540.938"
          fill="url(#paint11_linear_7163_6226)"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_7163_6226"
          x="0.000854492"
          y="-158.938"
          width="119"
          height="1149.88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_7163_6226"
          />
        </filter>
        <filter
          id="filter1_f_7163_6226"
          x="83.0009"
          y="-158.938"
          width="119"
          height="1149.88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_7163_6226"
          />
        </filter>
        <filter
          id="filter2_f_7163_6226"
          x="166.001"
          y="-158.938"
          width="119"
          height="1149.88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_7163_6226"
          />
        </filter>
        <filter
          id="filter3_f_7163_6226"
          x="249.001"
          y="-158.938"
          width="119"
          height="1149.88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_7163_6226"
          />
        </filter>
        <filter
          id="filter4_f_7163_6226"
          x="332.001"
          y="-158.938"
          width="119"
          height="1149.88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_7163_6226"
          />
        </filter>
        <filter
          id="filter5_f_7163_6226"
          x="415.001"
          y="-158.938"
          width="119"
          height="1149.88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_7163_6226"
          />
        </filter>
        <filter
          id="filter6_f_7163_6226"
          x="498.001"
          y="-158.938"
          width="119"
          height="1149.88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_7163_6226"
          />
        </filter>
        <filter
          id="filter7_f_7163_6226"
          x="581.001"
          y="-158.938"
          width="119"
          height="1149.88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_7163_6226"
          />
        </filter>
        <filter
          id="filter8_f_7163_6226"
          x="664.001"
          y="-158.938"
          width="119"
          height="1149.88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_7163_6226"
          />
        </filter>
        <filter
          id="filter9_f_7163_6226"
          x="747.001"
          y="-158.938"
          width="119"
          height="1149.88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_7163_6226"
          />
        </filter>
        <filter
          id="filter10_f_7163_6226"
          x="830.001"
          y="-158.938"
          width="119"
          height="1149.88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_7163_6226"
          />
        </filter>
        <filter
          id="filter11_f_7163_6226"
          x="913.001"
          y="-158.938"
          width="119"
          height="1149.88"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="17"
            result="effect1_foregroundBlur_7163_6226"
          />
        </filter>
        <linearGradient
          id="paint0_linear_7163_6226"
          x1="59.5009"
          y1="-124.938"
          x2="59.5009"
          y2="956.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#664E8E" stop-opacity="0.12" />
          <stop offset="1" stop-color="#0C0C0C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_7163_6226"
          x1="142.501"
          y1="-124.938"
          x2="142.501"
          y2="956.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#664E8E" stop-opacity="0.12" />
          <stop offset="1" stop-color="#0C0C0C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_7163_6226"
          x1="225.501"
          y1="-124.938"
          x2="225.501"
          y2="956.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#664E8E" stop-opacity="0.12" />
          <stop offset="1" stop-color="#0C0C0C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_7163_6226"
          x1="308.501"
          y1="-124.938"
          x2="308.501"
          y2="956.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#664E8E" stop-opacity="0.12" />
          <stop offset="1" stop-color="#0C0C0C" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_7163_6226"
          x1="391.501"
          y1="-124.938"
          x2="391.501"
          y2="956.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#664E8E" stop-opacity="0.12" />
          <stop offset="1" stop-color="#0C0C0C" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_7163_6226"
          x1="474.501"
          y1="-124.938"
          x2="474.501"
          y2="956.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#664E8E" stop-opacity="0.12" />
          <stop offset="1" stop-color="#0C0C0C" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_7163_6226"
          x1="557.501"
          y1="-124.938"
          x2="557.501"
          y2="956.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#664E8E" stop-opacity="0.12" />
          <stop offset="1" stop-color="#0C0C0C" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_7163_6226"
          x1="640.501"
          y1="-124.938"
          x2="640.501"
          y2="956.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#664E8E" stop-opacity="0.12" />
          <stop offset="1" stop-color="#0C0C0C" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_7163_6226"
          x1="723.501"
          y1="-124.938"
          x2="723.501"
          y2="956.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#664E8E" stop-opacity="0.12" />
          <stop offset="1" stop-color="#0C0C0C" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_7163_6226"
          x1="806.501"
          y1="-124.938"
          x2="806.501"
          y2="956.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#664E8E" stop-opacity="0.12" />
          <stop offset="1" stop-color="#0C0C0C" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_7163_6226"
          x1="889.501"
          y1="-124.938"
          x2="889.501"
          y2="956.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#664E8E" stop-opacity="0.12" />
          <stop offset="1" stop-color="#0C0C0C" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_7163_6226"
          x1="972.501"
          y1="-124.938"
          x2="972.501"
          y2="956.937"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#664E8E" stop-opacity="0.12" />
          <stop offset="1" stop-color="#0C0C0C" />
        </linearGradient>
      </defs>
    </svg>
  );
}
