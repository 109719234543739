import { createRoot } from 'react-dom/client';
import App from './app';
import { createBrowserRouter, Router, RouterProvider } from 'react-router-dom';

const container = document.getElementById('root');
const root = createRoot(container!); // Use createRoot for React 18+

const router = createBrowserRouter([
  {
    path: '*',
    element: <App />,
  },
]);
root.render(<RouterProvider router={router} />);
