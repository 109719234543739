import './index.scss';
import './prose.scss';

import AlchemyLogo from './assets/Logo';
import { XIcon } from './assets/share/XIcon';
import { LinkedInIcon } from './assets/share/LinkedInIcon';
import { LinkIcon } from './assets/share/LinkIcon';
import { Stars } from './assets/Stars';
import { useAsyncProcessManager } from './tools/async';
import { AsyncProcessManagerContext } from './tools/async/context';
import toast, { Toaster } from 'react-hot-toast';
import { useEffect, useMemo, useState } from 'react';
import { ICategory, IMainToolsData } from './api/interfaces';
import { BlobToolsAPI } from './api';
import { LoadingOverlay } from './components/loading';
import { AIService } from './api/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import { SmoothText } from './components/smoothText';
import { StarsGroup } from './assets/StarsGroup';
import { LandingTiles } from './assets/LandingTiles';
import { ProjectorLights } from './assets/ProjectorLights';
import SmoothImage from './components/smoothImage/SmoothImage';
import { Drawing } from './assets/Drawing';
import TextareaAutosize from 'react-textarea-autosize';
import { StringTools } from './tools/string';
import { Hashtag } from './assets/Hashtag';
import Copy from './assets/Copy';
import SaveDocument from './assets/SaveDocument';
import { Document } from './assets/Document';
import { Checkmark } from './assets/Checkmark';
import { QuoteL } from './assets/QuoteL';
import { QuoteR } from './assets/QuoteR';
import { Plus } from './assets/Plus';
import { Accordeon } from './components/accordeon';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const slug = useMemo(() => location.pathname.replaceAll('/', ''), [location]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const aiService = useMemo(() => new AIService(), []);

  const [data, setData] = useState<IMainToolsData | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
    null
  );

  const [textAreaValues, setTextAreaValues] = useState<{
    [key: string]: string;
  }>({});

  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [generatedResponse, setGeneratedResponse] = useState<string | null>(
    null
  );

  const [billingType, setBillingType] = useState<'monthly' | 'yearly'>(
    'monthly'
  );

  const asyncProcessManager = useAsyncProcessManager({
    onFirstActiveStart: () => {
      // setIsLoading(true);
    },
    onAllFinished: () => {
      // setIsLoading(false);
    },
    onError: (e, _, message, process) => {
      if (e.message == 'Network Error') {
        document.dispatchEvent(new CustomEvent('alchemyConnectionLost'));
      } else {
        // toast.error(`🤖 API error '${message}' on ${process.name}`);
        toast.error(`🤖 API error '${message}' on ${process.name}`);
        console.error('🛑 Error detected', e);
      }
    },
  });

  useEffect(() => {
    asyncProcessManager?.doProcess({
      name: 'Get main data',
      onError: () => {
        setIsLoading(false);
        return undefined;
      },
      action: async () => {
        setIsLoading(true);
        const newData = await BlobToolsAPI.getMainData();

        setData(newData);
        setIsLoading(false);
      },
    });
  }, []);

  const promptsInCurrentCategory = useMemo(
    () => (selectedCategory ?? data?.results?.[0])?.prompts ?? [],
    [selectedCategory, data]
  );

  // const activePrompt = useMemo(
  //   () =>
  //     data?.results
  //       ?.flatMap(category => category.prompts)
  //       ?.find(item => item.slug === slug),
  //   [data, slug]
  // );
  const activePrompt: any = null;

  const runActivePrompt = () => {
    if (!activePrompt) {
      toast.error('No active prompt');
      return;
    }
    asyncProcessManager.doProcess({
      name: 'Start generation',
      action: async () => {
        setIsGenerating(true);
        setGeneratedResponse('');

        await aiService.generate({
          request: {
            prompt: activePrompt?.id,
            input_fields: textAreaValues,
          },
          onGenerationChunkReceived: chunk => {
            setGeneratedResponse(prev => prev + chunk.diff);
          },
          onGenerationEnded: () => {
            setIsGenerating(false);
          },
        });
      },
    });
  };

  const handleTextAreaChange = (name: string, value: string) => {
    setTextAreaValues(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log(activePrompt);
  }, [activePrompt]);

  useEffect(() => {
    setTextAreaValues({});
  }, [activePrompt]);

  const shareUrl = window.location.href; // Get the current page URL
  const pageTitle = document.title; // Get the current page title (optional)

  const handleShare = (platform: string) => {
    let shareLink = '';

    switch (platform) {
      case 'x':
        shareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(pageTitle)}`;
        break;
      case 'linkedin':
        shareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(pageTitle)}`;
        break;
      case 'instagram':
        toast.error(
          'Instagram does not support direct URL sharing. Please share manually.'
        );
        return; // Exit without opening a link
      default:
        return;
    }

    window.open(shareLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <AsyncProcessManagerContext.Provider value={asyncProcessManager}>
      <Toaster
        containerClassName="ktulhu-toaster"
        containerStyle={{
          zIndex: 999999999999999,
          width: '100%',
        }}
        toastOptions={{
          style: {
            border: '1px solid var(--app-light-1a)',
            background: 'var(--app-toaster-background)',
            color: 'var(--app-text)',
            display: 'flex',
          },
        }}
      />
      <div className="container">
        <header className="header">
          <div className="group">
            <a href="https://alchemy-app.com">
              <AlchemyLogo />
            </a>
          </div>
          <nav>
            <ul className="nav-links">
              <li>
                <a href="https://alchemy-app.com/features">Features</a>
              </li>
              <li>
                <a href="https://alchemy-app.com/pricing">Pricing</a>
              </li>
              <li>
                <a href="https://alchemy-app.com/help">Help</a>
              </li>
              <li>
                <a href="https://web.alchemy-app.com/"><b>Open app</b></a>
              </li>
            </ul>
          </nav>
        </header>

        <main className={!activePrompt ? ' landing' : ''}>
          {activePrompt ? (
            <>
              <section className="intro">
                <div className="main-group">
                  <>
                    <div className="prompt-information">
                      <h1>{activePrompt?.name}</h1>
                      <p>{activePrompt?.description}</p>
                    </div>
                    <div className="share-section">
                      <p>Share this prompt:</p>
                      <div className="share-buttons">
                        <button onClick={() => handleShare('x')}>
                          <XIcon />
                        </button>
                        <button onClick={() => handleShare('linkedin')}>
                          <LinkedInIcon />
                        </button>
                        <button
                          onClick={async () => {
                            try {
                              await navigator.clipboard.writeText(shareUrl);
                              toast.success('Link copied to clipboard!');
                            } catch (err) {
                              console.error(err);
                              toast.error('Failed to copy');
                            }
                          }}
                        >
                          <LinkIcon />
                        </button>
                      </div>
                    </div>
                  </>
                </div>

                <div className="prompt-card">
                  <div className="heading">TRY IT HERE</div>
                  <div className="fields">
                    {generatedResponse !== null ? (
                      <div
                        className={
                          'generated-text' + (isGenerating ? ' generating' : '')
                        }
                      >
                        <SmoothText
                          markCitations={true}
                          text={generatedResponse}
                          speed={1}
                          enabled={isGenerating}
                        />
                      </div>
                    ) : (
                      <>
                        {activePrompt?.inputs?.map((input: any, index: any) => (
                          <div className="form-group" key={index}>
                            <div className="label">{input.variable_name}</div>
                            <div className="textarea-wrapper">
                              <TextareaAutosize
                                maxRows={7}
                                placeholder={input.placeholder}
                                value={
                                  textAreaValues[input.variable_name] || ''
                                }
                                onChange={e =>
                                  handleTextAreaChange(
                                    input.variable_name,
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                        <button
                          onClick={() => runActivePrompt()}
                          className="run-prompt"
                        >
                          Run this prompt
                        </button>
                      </>
                    )}
                  </div>
                </div>

                {generatedResponse && (
                  <div className="action-section">
                    <button
                      onClick={() =>
                        (window.location.href =
                          'https://web.alchemy-app.com/sign-up')
                      }
                    >
                      Get more of these prompts
                    </button>
                    <div className="loved-by">
                      <StarsGroup />
                      <p>Loved by over 2000 solopreneur</p>
                    </div>
                  </div>
                )}
              </section>
              <section className="qa">
                <h2>
                  Harness the power of AI with <span>Alchemy</span>
                </h2>

                <div className="qa-group">
                  <h4>What’s Alchemy?</h4>
                  <p>
                    Alchemy is designed to empower solopreneurs and small
                    businesses by providing world-class marketing strategies
                    instantly. With over 100 plug-and-play prompts, it
                    simplifies content creation, research, and marketing
                    execution, helping users enhance their marketing ROI with
                    proven, data-backed tactics.
                  </p>
                </div>

                <div className="qa-group">
                  <h4>Who is it for?</h4>
                  <p>
                    Alchemy is perfect for solopreneurs and small business
                    owners who are overwhelmed by the complexity of marketing
                    tools and strategies. It’s for individuals who want to
                    leverage AI without spending thousands of hours in research
                    or becoming experts themselves. With Alchemy, users can skip
                    the guesswork and instantly access battle-tested marketing
                    strategies that drive real results.
                  </p>
                </div>

                <div className="qa-group">
                  <h4>Why Alchemy?</h4>
                  <p>
                    Most solopreneurs struggle with mediocre marketing results
                    due to generic prompts and overwhelming AI tools. Alchemy
                    simplifies this process by providing expertly curated
                    prompts and marketing tools that eliminate guesswork.
                    Whether you’re struggling with content creation, paid ads,
                    or audience targeting, Alchemy cuts through the noise and
                    delivers high-impact marketing strategies, helping you save
                    time and increase conversion rates effortlessly.
                  </p>
                </div>

                <div className="qa-group">
                  <h4>Are you looking for prompt made by an expert?</h4>
                  <p>
                    Check out our prompt library, or send us an email at{' '}
                    <a href="mailto:hello@alchemy-app.com">
                      hello@alchemy-app.com
                    </a>{' '}
                    with your idea for a prompt, and we’ll designed a prompt
                    with knowledge from industry experts.
                  </p>
                </div>
              </section>
              <section className="prompts-and-categories-wrapper">
                <h2>Check out our other prompts</h2>

                <div className="prompts-and-categories">
                  <div className="categories">
                    {data?.results?.map((category, index) => (
                      <div
                        key={index}
                        onClick={() => setSelectedCategory(category)}
                        className={
                          'category' +
                          (category.id === selectedCategory?.id ||
                          (index === 0 && !selectedCategory)
                            ? ' active'
                            : '')
                        }
                      >
                        <img src={category.white_icon} alt="Category icon" />
                        <span>{category.name}</span>
                      </div>
                    ))}
                  </div>
                  <div className="prompts">
                    {promptsInCurrentCategory?.map(prompt => (
                      <>
                        <div
                          onClick={() => {
                            navigate('/' + prompt.slug);
                            document.body.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            });
                          }}
                          className="prompt"
                        >
                          <p className="title">{prompt.name}</p>
                          <p className="description">{prompt.description}</p>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </section>
              <section className="promo">
                <h2>Time to make the best of AI for marketing</h2>
                <p>You’re in for a surprise.</p>

                <button
                  onClick={() =>
                    (window.location.href =
                      'https://web.alchemy-app.com/sign-up')
                  }
                >
                  Start 7-day free trial
                </button>
              </section>
            </>
          ) : (
            <>
              <section className="intro-landing landing-section">
                <div className="gradient-bg" />
                <div className="gray-bg-on-first-slide"></div>
                <div className="main-info">
                  <h1>
                    Transform your marketing with AI, built for solopreneurs
                  </h1>

                  <div className="advantages">
                    <div className="advantage">
                      Skip thousands of hours in research and strategy
                    </div>
                    <div className="advantage">
                      Apply world class strategies instantly with over 100 plug
                      & play prompts
                    </div>
                    <div className="advantage">
                      Boost your marketing ROI with data-backed, proven tactics
                    </div>
                  </div>

                  <div className="start-link">
                    <button
                      onClick={() =>
                        (window.location.href =
                          'https://web.alchemy-app.com/sign-up')
                      }
                    >
                      Get started for free
                    </button>

                    <div className="loved-by">
                      <StarsGroup />
                      <p>2000+ users</p>
                    </div>
                  </div>
                </div>

                <div className="badges">
                  <img src="/assets/badges/badge-1.svg" />
                  <img src="/assets/badges/badge-2.svg" />
                  <img src="/assets/badges/badge-3.svg" />
                  <img src="/assets/badges/badge-4.svg" />
                  <img src="/assets/badges/badge-5.svg" />
                </div>

                <div className="graphics">
                  <SmoothImage alt="Chat screenshot" src="/assets/chat.webp" />
                </div>

                <img src="/assets/llms.svg" className="llms" />
              </section>
              <section className="prompts-and-categories-wrapper landing-section">
                <div className="badge">PROMPTS</div>

                <h2>
                  Go from generic to genius with the best prompts on the market
                </h2>

                <p>Plug & play prompts: Your secret weapon</p>

                <div className="prompts-and-categories">
                  <div className="categories">
                    {data?.results?.map((category, index) => (
                      <div
                        key={index}
                        onClick={() => setSelectedCategory(category)}
                        className={
                          'category' +
                          (category.id === selectedCategory?.id ||
                          (index === 0 && !selectedCategory)
                            ? ' active'
                            : '')
                        }
                      >
                        <span>{category.name}</span>
                      </div>
                    ))}
                  </div>
                  <div className="prompts">
                    {promptsInCurrentCategory?.map(prompt => (
                      <>
                        <div
                          onClick={() => {
                            document.location.href = 'https://web.alchemy-app.com/demo/' + prompt.slug
                            document.body.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            });
                          }}
                          className="prompt"
                        >
                          <div className="prompt-main">
                            <p className="title">
                              {StringTools.truncate(prompt.name, 35)}
                            </p>
                            <p className="description">
                              {StringTools.truncate(prompt.description, 130)}
                            </p>
                          </div>
                          <div className="prompt-nav">
                            <span></span>
                            <img src="/assets/chevron-right.svg" alt="" />
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </section>
              <section className="problem-solution">
                <div className="problem">
                  <div className="heading">
                    <div className="badge">PROBLEM</div>
                    <h2>
                      Mediocre prompts are killing your marketing potential
                    </h2>
                  </div>
                  <div className="problem-sections">
                    <div className="problem-section">
                      <SmoothImage alt="Emoji" src="/assets/emoji/1.png" />
                      <p>
                        <b>You're buried under a chaos of AI tools, </b>craving
                        simplicity that’s frustratingly out of reach despite
                        promises of easy solutions.
                      </p>
                    </div>

                    <div className="problem-section">
                      <SmoothImage alt="Emoji" src="/assets/emoji/2.png" />
                      <p>
                        <b>You are tired of mediocre prompts </b>and generic ai
                        results, but you understand the need for good marketing.
                      </p>
                    </div>

                    <div className="problem-section">
                      <SmoothImage alt="Emoji" src="/assets/emoji/3.png" />
                      <p>
                        <b>
                          Your stack of marketing tips & tricks is overwhelming{' '}
                        </b>
                        (so you never use them and your business suffers because
                        of it)
                      </p>
                    </div>

                    <div className="problem-section">
                      <SmoothImage alt="Emoji" src="/assets/emoji/4.png" />
                      <p>
                        <b>
                          You feel like your marketing is never persuasive or
                          engaging.{' '}
                        </b>
                        You don’t have time to become an expert and produce
                        marketing that converts.
                      </p>
                    </div>
                  </div>
                </div>

                <Drawing />

                <div className="solution">
                  <div className="heading">
                    <div className="badge">SOLUTION</div>
                    <h2>
                      Achieving top tier results with AI has never been so
                      simple
                    </h2>
                    <p className="heading">
                      Skip months of research, start winning today
                    </p>
                  </div>

                  <div className="articles">
                    <article>
                      <h4>Battle tested strategies</h4>
                      <p>
                        We cut through the noise to deliver proven,
                        battle-tested marketing strategies that drive real
                        results—no trial and error.
                      </p>
                      <div className="image-placeholder"></div>
                    </article>
                    <article>
                      <h4>Curated for you</h4>
                      <p>
                        Our engineers create plug-and-play prompts for every
                        marketing task, from research to viral content—advanced
                        marketing, simplified.
                      </p>
                      <div className="image-placeholder"></div>
                    </article>
                    <article>
                      <h4>Plug & Play Expertise</h4>
                      <p>
                        With one click, our prompts deliver instant expertise,
                        letting you implement advanced strategies with zero
                        setup.
                      </p>
                      <div className="image-placeholder"></div>
                    </article>
                  </div>
                </div>
              </section>
              <section className="comparison">
                <div className="heading">
                  <div className="badge">COMPARISON</div>
                  <h2>Prompts that make your competition sweat</h2>
                  <p>
                    Access unlimited features from top AI models effortlessly.
                  </p>
                </div>
                <div className="articles">
                  <article>
                    <h4>Them</h4>
                    <p>
                      We cut through the noise to deliver proven, battle-tested
                      marketing strategies that drive real results—no trial and
                      error.
                    </p>
                    <div className="image-placeholder"></div>
                  </article>
                  <article>
                    <h4>Alchemy</h4>
                    <p>
                      Our engineers create plug-and-play prompts for every
                      marketing task, from research to viral content—advanced
                      marketing, simplified.
                    </p>
                    <div className="image-placeholder"></div>
                  </article>
                </div>
              </section>
              <section className="solution">
                <div className="heading">
                  <div className="badge">TOOLS</div>
                  <h2>The right tools to go with the prompts</h2>
                </div>
                <div className="articles">
                  <article>
                    <img
                      src="./assets/emoji/5.png"
                      width="40px"
                      alt="robot emoji"
                    />
                    <h4>All your favorite AI models in one place</h4>
                    <p>
                      Access unlimited features from top AI models effortlessly.
                    </p>
                    <div className="image-placeholder"></div>
                  </article>

                  <article>
                    <img
                      src="./assets/emoji/6.png"
                      width="40px"
                      alt="scissors emoji"
                    />
                    <h4>Instant summaries of websites and youtube</h4>
                    <p>Extract key insights from lengthy content quickly.</p>
                    <div className="image-placeholder"></div>
                  </article>

                  <article>
                    <img
                      src="./assets/emoji/7.png"
                      width="40px"
                      alt="note emoji"
                    />
                    <h4>Create snippets for repetitive Statements</h4>
                    <p>Simplify repetitive typing tasks with saved snippets.</p>
                    <div className="image-placeholder"></div>
                  </article>

                  <article>
                    <img
                      src="./assets/emoji/8.png"
                      width="40px"
                      alt="search emoji"
                    />
                    <h4>AI-powered searches</h4>
                    <p>
                      Conduct efficient searches for distilled, relevant
                      information.
                    </p>
                    <div className="searches-preview">
                      <div className="message">
                        marketing trends september 2024
                      </div>
                      <div className="message">
                        <div className="logo">
                          <AlchemyLogo dark /> Alchemy
                        </div>
                        <div className="sub-message">
                          <b>AI is transforming marketing</b> – 85% of marketers
                          report using AI to streamline content creation and
                          personalization.{' '}
                          <span className="outlined-number">1</span>
                        </div>
                        <div className="sub-message">
                          <b>Authenticity matters</b> – Brands are focusing on
                          genuine community-building as AI-driven content rises.{' '}
                          <span className="outlined-number">2</span>
                        </div>

                        <div className="sub-message">
                          <b>SEO is evloving</b> – Search engines are shifting
                          toward video and audio content for better visibility.{' '}
                          <span className="outlined-number">3</span>
                        </div>
                      </div>
                    </div>
                  </article>

                  <article>
                    <img
                      src="./assets/emoji/9.png"
                      width="40px"
                      alt="artist emoji"
                    />
                    <h4>Create your custom prompts and share</h4>
                    <p>Utilize a wide range of expertly crafted prompts.</p>
                    <div className="custom-prompts-preview">
                      <div className="message">
                        <div className="tag">
                          <Hashtag />
                          brandvalue
                        </div>
                        <div>
                          EcoFresh ensures sustainability and quality through
                          eco-friendly practices, building trust with customers.
                        </div>
                      </div>
                      <div className="message">
                        <div className="tag">
                          <Hashtag />
                          services
                        </div>
                        <div>
                          Get customized cleaning schedules, eco-friendly
                          product suggestions, and green living tips with our AI
                          prompts.
                        </div>
                      </div>
                      <div className="message">
                        <div className="tag">
                          <Hashtag />
                          mybusiness
                        </div>
                        <div>
                          Simplify cleaning appointments, customer feedback, and
                          eco-product management with our all-in-one tool.
                        </div>
                      </div>
                    </div>
                  </article>

                  <article>
                    <img
                      src="./assets/emoji/10.png"
                      width="40px"
                      alt="save emoji"
                    />
                    <h4>Save output on the go</h4>
                    <p>Simplify repetitive typing tasks with saved snippets.</p>
                    <div className="saving-preview">
                      <div className="message">
                        <div className="logo">
                          <AlchemyLogo dark /> Alchemy
                        </div>
                        <div className="sub-message">
                          Boost your business with data-driven tools that
                          elevate every aspect of your marketing. From
                          high-converting ad copy to SEO optimization, our
                          solutions are built from proven results and real-world
                          insights. Achieve measurable growth with precision and
                          simplicity—start driving success today.
                        </div>
                        <div className="sub-message buttons">
                          <div className="copy">
                            <Copy /> Copy
                          </div>
                          <div className="save">
                            <SaveDocument /> Save
                          </div>
                        </div>
                      </div>
                      <div className="message">
                        <div className="save-document">
                          <SaveDocument /> Create new document
                        </div>
                        <div className="sub-message">Recent Documents</div>
                        <div className="sub-message">
                          <div className="document">
                            <Document />
                            Pitch Creation Template
                          </div>
                          <div className="document">
                            <Document />
                            Expand Ideas Collaboratively
                          </div>
                          <div className="document">
                            <Document />
                            Essay Planning AI Solution
                          </div>
                          <div className="document">
                            <Document />
                            Expand Ideas Collaboratively
                          </div>
                          <div className="document">
                            <Document />
                            Creative Storytelling: Imagination Igniter
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </section>
              <section className="tarrifs-wrapper">
                <div className="tarrifs">
                  <div className="cards-wrapper">
                    <div className="left-card-wrapper">
                      <div className="heading">Ready to leverage AI?</div>
                      <div className="billing-selector">
                        <div
                          className={`option ${billingType === 'monthly' ? 'active' : ''}`}
                          onClick={() => setBillingType('monthly')}
                        >
                          Monthly
                        </div>
                        <div
                          className={`option ${billingType === 'yearly' ? 'active' : ''}`}
                          onClick={() => setBillingType('yearly')}
                        >
                          Yearly <span>Save 40%</span>
                        </div>
                      </div>
                      <div className="card">
                        <div className="list-wrapper">
                          Free
                          <ul>
                            <li>
                              <Checkmark />
                              1000 Chat Tokens/Day
                            </li>
                            <li>
                              <Checkmark />
                              Prompt Library
                            </li>
                            <li>
                              <Checkmark />
                              Quick Prompts
                            </li>
                            <li>
                              <Checkmark />
                              Speech to Text
                            </li>
                            <li>
                              <Checkmark />
                              Themes
                            </li>
                            <li>
                              <Checkmark />5 Favorite Prompts
                            </li>
                            <li>
                              <Checkmark />5 Favorite Prompts
                            </li>
                            <li>
                              <Checkmark />5 Custom Prompts
                            </li>
                            <li>
                              <Checkmark />5 Documents
                            </li>
                          </ul>
                        </div>
                        <button>Start for free</button>
                      </div>
                    </div>
                    <div className="card paid">
                      <div className="list-wrapper">
                        Pro
                        <div className="price-wrapper">
                          <div className="price">$39.99 </div>
                          per month, billed monthly
                        </div>
                        <ul>
                          <li>
                            <Checkmark />
                            Everything in Free
                          </li>
                          <li>
                            <Checkmark />
                            Unlimited Chat Tokens
                          </li>
                          <li>
                            <Checkmark />
                            Unlimited Favorite Prompts
                          </li>
                          <li>
                            <Checkmark />
                            Unlimited Custom Prompts
                          </li>
                          <li>
                            <Checkmark />
                            Unlimited Documents
                          </li>
                          <li>
                            <Checkmark />
                            Unlimited Snippets
                          </li>
                          <li>
                            <Checkmark />
                            Webpage Summarization
                          </li>
                          <li>
                            <Checkmark />
                            YouTube Summarization
                          </li>
                          <li>
                            <Checkmark />
                            AI Search
                          </li>
                          <li>
                            <Checkmark />
                            Image OCR
                          </li>
                          <li>
                            <Checkmark />
                            Chat with PDF
                          </li>

                          <li>
                            <Checkmark />
                            Capture Screenshots
                          </li>
                        </ul>
                      </div>
                      <button>Get Pro</button>
                      <div className="card-badge">Recommended</div>
                    </div>
                  </div>
                  <div className="quote-wrapper">
                    <div className="quote-decorations">
                      <QuoteL />
                      <QuoteR />
                    </div>
                    <div className="quote">
                      Looking to get just the prompts separately? We've got a
                      deal for you! Buy our complete prompt pack for $99 and get
                      one month of Alchemy for free!
                    </div>
                    <a>Buy the prompt pack for $99</a>
                  </div>
                </div>
              </section>
              <section className="faq-wrapper">
                <div className="faq">
                  <h4>FAQs</h4>
                  <Accordeon
                    title={
                      <>
                        What is Alchemy? <Plus />
                      </>
                    }
                  >
                    Test text
                  </Accordeon>
                  <Accordeon
                    title={
                      <>
                        Who is Alchemy for? <Plus />
                      </>
                    }
                  />
                  <Accordeon
                    title={
                      <>
                        What differentiates Alchemy from other AI tools?
                        <Plus />
                      </>
                    }
                  />
                  <Accordeon
                    title={
                      <>
                        What are the Knowledge Management features of Alchemy?
                        <Plus />
                      </>
                    }
                  />
                  <Accordeon
                    title={
                      <>
                        Is Alchemy free to use?
                        <Plus />
                      </>
                    }
                  />
                  <Accordeon
                    title={
                      <>
                        How does Alchemy handle user data and privacy?
                        <Plus />
                      </>
                    }
                  />
                </div>
              </section>
              <section className="bottom-hero-wrapper">
                <div className="bottom-hero">
                  <div className="line1">the tool built to get you good</div>
                  <div className="line2">
                    Unleash your
                    <br /> marketing genius
                  </div>
                  <button>Start for free</button>
                  <img src="./assets/bottom-hero.png" alt="bottom hero image" />
                </div>
              </section>
            </>
          )}
        </main>

        <footer>
          <a href="https://alchemy-app.com/">
            <AlchemyLogo />
          </a>
          <nav>
            <ul className="nav-links">
              <li>
                <a href="https://alchemy-app.com/privacy">Privacy</a>
              </li>
              <li>
                <a href="https://alchemy-app.com/terms">Terms</a>
              </li>
              <li>
                <a href="https://alchemy-app.com/support">Support</a>
              </li>
              <li>
                <a href="https://alchemy-app.com/">
                  © {new Date().getFullYear()} Alchemy
                </a>
              </li>
            </ul>
          </nav>
        </footer>
      </div>
      <LoadingOverlay isGlobal={true} active={isLoading} />
    </AsyncProcessManagerContext.Provider>
  );
}

export default App;
