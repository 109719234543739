export function LinkedInIcon() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1875 0.929688H0.8125C0.466797 0.929688 0.1875 1.20898 0.1875 1.55469V15.9297C0.1875 16.2754 0.466797 16.5547 0.8125 16.5547H15.1875C15.5332 16.5547 15.8125 16.2754 15.8125 15.9297V1.55469C15.8125 1.20898 15.5332 0.929688 15.1875 0.929688ZM4.82227 14.2441H2.50391V6.78711H4.82227V14.2441ZM3.66406 5.76758C3.39829 5.76758 3.13849 5.68877 2.91751 5.54112C2.69654 5.39346 2.5243 5.1836 2.4226 4.93806C2.32089 4.69252 2.29428 4.42234 2.34613 4.16168C2.39798 3.90101 2.52596 3.66158 2.71389 3.47365C2.90181 3.28573 3.14125 3.15775 3.40191 3.1059C3.66257 3.05405 3.93276 3.08066 4.17829 3.18236C4.42383 3.28407 4.6337 3.4563 4.78135 3.67728C4.929 3.89826 5.00781 4.15806 5.00781 4.42383C5.00586 5.16602 4.4043 5.76758 3.66406 5.76758ZM13.502 14.2441H11.1855V10.6172C11.1855 9.75195 11.1699 8.64062 9.98047 8.64062C8.77539 8.64062 8.58984 9.58203 8.58984 10.5547V14.2441H6.27539V6.78711H8.49805V7.80664H8.5293C8.83789 7.2207 9.59375 6.60156 10.7227 6.60156C13.0703 6.60156 13.502 8.14648 13.502 10.1543V14.2441Z"
        fill="white"
      />
    </svg>
  );
}
