import React from 'react';

export const Plus = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16.375" r="16" fill="black" fillOpacity="0.12" />
      <g clipPath="url(#clip0_8567_2023)">
        <path
          d="M16 11.375V21.375"
          stroke="#222222"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11 16.375H21"
          stroke="#222222"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8567_2023">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(10 10.375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
