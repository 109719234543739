import React from 'react';

export const QuoteR = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="41"
      viewBox="0 0 36 41"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4884 38.1302C26.787 37.1519 30.2405 36.6584 33.5907 36.2063C34.3122 36.1115 34.9822 36.6404 35.0337 37.3856C35.1368 38.1313 34.6211 38.8133 33.9511 38.9081C30.704 39.3433 27.3542 39.809 24.2102 40.7508C23.5402 40.9551 22.8183 40.5346 22.6122 39.8112C22.406 39.0884 22.8184 38.335 23.4884 38.1302Z"
        fill="#6144E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4853 24.2592C18.7939 18.5338 24.7726 13.5453 29.9782 7.66625C30.4421 7.11718 31.2667 7.08776 31.7821 7.60034C32.2975 8.11345 32.3489 8.97627 31.8335 9.52534C26.6279 15.4213 20.6492 20.4261 15.3405 26.1684C14.8251 26.7044 14.0007 26.7121 13.4853 26.1848C13.0214 25.658 12.9699 24.7952 13.4853 24.2592Z"
        fill="#6144E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.54361 2.23651C3.38899 5.51349 3.23467 8.79047 3.08005 12.068C3.08005 12.8186 2.46126 13.3982 1.7397 13.3617C1.01813 13.3246 0.503028 12.6857 0.503028 11.9345C0.65765 8.65211 0.811969 5.37023 0.966591 2.08835C1.01813 1.33773 1.63672 0.761974 2.35828 0.802828C3.02831 0.843681 3.59516 1.48644 3.54361 2.23651Z"
        fill="#6144E8"
      />
    </svg>
  );
};
