import React from 'react';

const SaveDocument = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.56461 12.8695H5.47272C4.81253 12.8695 4.27734 12.3159 4.27734 11.633V5.14088C4.27734 4.45794 4.81253 3.9043 5.47272 3.9043H9.20827L11.7484 6.53205V7.92321"
        stroke="currentColor"
        strokeWidth="1.16735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 9.88086V12.8693"
        stroke="currentColor"
        strokeWidth="1.16735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4943 11.375H9.50586"
        stroke="currentColor"
        strokeWidth="1.16735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7482 6.89271H8.75977V3.9043"
        stroke="currentColor"
        strokeWidth="1.16735"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SaveDocument;
