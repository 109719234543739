import React from 'react';

export const QuoteL = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="41"
      viewBox="0 0 36 41"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5116 38.1302C9.21297 37.1519 5.75945 36.6584 2.40932 36.2063C1.68775 36.1115 1.01783 36.6404 0.966286 37.3856C0.863205 38.1313 1.37891 38.8133 2.04894 38.9081C5.29599 39.3433 8.64582 39.809 11.7898 40.7508C12.4598 40.9551 13.1817 40.5346 13.3878 39.8112C13.594 39.0884 13.1816 38.335 12.5116 38.1302Z"
        fill="#6144E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5147 24.2592C17.2061 18.5338 11.2274 13.5453 6.02179 7.66625C5.55792 7.11718 4.73327 7.08776 4.21787 7.60034C3.70246 8.11345 3.65112 8.97627 4.16653 9.52534C9.37212 15.4213 15.3508 20.4261 20.6595 26.1684C21.1749 26.7044 21.9993 26.7121 22.5147 26.1848C22.9786 25.658 23.0301 24.7952 22.5147 24.2592Z"
        fill="#6144E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4564 2.23651C32.611 5.51349 32.7653 8.79047 32.9199 12.068C32.9199 12.8186 33.5387 13.3982 34.2603 13.3617C34.9819 13.3246 35.497 12.6857 35.497 11.9345C35.3424 8.65211 35.188 5.37023 35.0334 2.08835C34.9819 1.33773 34.3633 0.761974 33.6417 0.802828C32.9717 0.843681 32.4048 1.48644 32.4564 2.23651Z"
        fill="#6144E8"
      />
    </svg>
  );
};
